import { Stack, styled } from "@mui/material";

export interface LockScreenProps {
  onClose?: () => void;
}

const Root = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(-3, -2, -3),
  height: "100vh",
  width: "100vw",
}));

const BackgroundImage = styled(Stack)(() => ({
  backgroundImage: 'url("/ios-wallpaper.jpeg")',
  opacity: "50%",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  height: "100%",
  width: "100%",
  zIndex: 1,
}));

const LockScreen = ({ onClose }: LockScreenProps) => {
  return (
    <Root onClick={onClose}>
      <BackgroundImage />
      <img src="/lock-screen-demo.svg" alt="lock-screen-demo" style={{ zIndex: 2 }} />
    </Root>
  );
};

export default LockScreen;
