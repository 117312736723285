export type FontStyle = "Regular" | "Medium" | "SemiBold" | "Bold";

const fontStyles: Record<FontStyle, number> = {
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
};

export default fontStyles;
