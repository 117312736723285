interface IconProps {
  color?: string;
}

const DispatchIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 33 30" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.875 15.275C28.875 8.4125 23.0175 3.75 16.5 3.75C10.0513 3.75 4.125 8.3125 4.125 15.35C3.3 15.775 2.75 16.575 2.75 17.5V20C2.75 21.375 3.9875 22.5 5.5 22.5H6.875V14.875C6.875 10.0375 11.1788 6.125 16.5 6.125C21.8213 6.125 26.125 10.0375 26.125 14.875V23.75H15.125V26.25H26.125C27.6375 26.25 28.875 25.125 28.875 23.75V22.225C29.6863 21.8375 30.25 21.075 30.25 20.175V17.3C30.25 16.425 29.6863 15.6625 28.875 15.275Z"
        fill={color}
      />
      <path
        d="M12.375 17.5C13.1344 17.5 13.75 16.9404 13.75 16.25C13.75 15.5596 13.1344 15 12.375 15C11.6156 15 11 15.5596 11 16.25C11 16.9404 11.6156 17.5 12.375 17.5Z"
        fill={color}
      />
      <path
        d="M20.625 17.5C21.3844 17.5 22 16.9404 22 16.25C22 15.5596 21.3844 15 20.625 15C19.8656 15 19.25 15.5596 19.25 16.25C19.25 16.9404 19.8656 17.5 20.625 17.5Z"
        fill={color}
      />
      <path
        d="M24.7499 13.7875C24.0899 10.225 20.6799 7.5 16.5686 7.5C12.4024 7.5 7.9199 10.6375 8.2774 15.5625C11.6736 14.3 14.2311 11.55 14.9599 8.2C16.7611 11.4875 20.4599 13.75 24.7499 13.7875Z"
        fill={color}
      />
    </svg>
  );
};

export default DispatchIcon;
