import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
import { ReadyState, useVehicleDispatch, useVehicleState, vehicleActions } from "../../../context/VehicleContext";
import {
  preShiftChecklistValidationSchema,
  CrewField,
  crewValidationSchema,
  inspectionValidationSchema,
  VehicleLoginFormSchema,
  vehicleLoginInitialValues,
  vehicleValidationSchema,
} from "./schema";
import Button from "../../Buttons/Button";
// mocks
import { mockVehicleInfo } from "../../../__MOCKS__/vehicle";
import { mockCrewMembers } from "../../../__MOCKS__/crew";
// Steps
import Vehicle from "./Vehicle";
import Crew from "./Crew";
import Inspection from "./Inspection";
import PreShiftChecklist from "./PreShiftChecklist";
import { userActions, useUserDispatch, Status } from "../../../context/UserContext";
import { incidentActions, useIncidentDispatch } from "../../../context/IncidentContext";

const VehicleLoginForm = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const { readyState } = useVehicleState();
  const vehicleDispatch = useVehicleDispatch();
  const userDispatch = useUserDispatch();
  const incidentDispatch = useIncidentDispatch();

  useEffect(() => {
    if (readyState === ReadyState.LOGGED_IN) {
      navigate("/", { replace: true });
    }
  }, [readyState, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const formik = useFormik<VehicleLoginFormSchema>({
    initialValues: vehicleLoginInitialValues,
    validationSchema: {
      1: vehicleValidationSchema,
      2: crewValidationSchema,
      3: inspectionValidationSchema,
      4: preShiftChecklistValidationSchema,
    }[step],
    onSubmit: ({ vehicle, crew }) => {
      switch (step) {
        case 1:
          nextStep();
          break;
        case 2:
          vehicleActions.crewOffDuty(vehicleDispatch);
          nextStep();
          break;
        case 3:
          nextStep();
          break;
        case 4:
          vehicleActions.loginToVehicle(
            vehicleDispatch,
            {
              // prefill empty fields with mock data for demo purposes
              ...mockVehicleInfo,
              ...vehicle,
              portableDevices: vehicle.portableDevices || mockVehicleInfo.portableDevices,
            },
            mockCrewMembers.filter(({ id }) => !!crew[CrewField.Members].find((crewId) => crewId === id)),
          );
          navigate("/");
          userActions.setStatus(userDispatch, Status.Activated);
          incidentActions.clearIncident(incidentDispatch);
          break;

        default:
          break;
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Stack spacing={8}>
          {
            {
              1: <Vehicle goBack={() => navigate("/")} />,
              2: <Crew goBack={prevStep} />,
              3: <Inspection goBack={prevStep} />,
              4: <PreShiftChecklist goBack={prevStep} />,
            }[step]
          }
          <Button
            endIcon={
              <Box width="24px" height="24px">
                <ArrowRightIcon color={!formik.isValid ? "rgba(255, 255, 255, 0.3)" : undefined} />
              </Box>
            }
            disabled={!formik.isValid}
            type="submit"
          >
            Next
          </Button>
        </Stack>
      </form>
    </FormikProvider>
  );
};

export default VehicleLoginForm;
