interface IconProps {
  color?: string;
}

const EyeIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_161_3653)">
        <path
          d="M36.8457 14.9134C34.39 10.9139 28.804 4.20374 19 4.20374C9.19596 4.20374 3.60996 10.9139 1.15421 14.9134C0.395045 16.1413 -0.00708008 17.5564 -0.00708008 19C-0.00708008 20.4436 0.395045 21.8587 1.15421 23.0866C3.60996 27.0861 9.19596 33.7962 19 33.7962C28.804 33.7962 34.39 27.0861 36.8457 23.0866C37.6049 21.8587 38.007 20.4436 38.007 19C38.007 17.5564 37.6049 16.1413 36.8457 14.9134ZM34.1461 21.4288C32.0371 24.8583 27.2634 30.6296 19 30.6296C10.7365 30.6296 5.96279 24.8583 3.85379 21.4288C3.40276 20.699 3.16386 19.858 3.16386 19C3.16386 18.142 3.40276 17.301 3.85379 16.5712C5.96279 13.1417 10.7365 7.3704 19 7.3704C27.2634 7.3704 32.0371 13.1353 34.1461 16.5712C34.5972 17.301 34.8361 18.142 34.8361 19C34.8361 19.858 34.5972 20.699 34.1461 21.4288Z"
          fill={color}
        />
        <path
          d="M18.9999 11.0833C17.4342 11.0833 15.9035 11.5476 14.6017 12.4175C13.2998 13.2874 12.2851 14.5238 11.6859 15.9704C11.0867 17.417 10.9299 19.0088 11.2354 20.5445C11.5408 22.0802 12.2948 23.4908 13.402 24.5979C14.5092 25.7051 15.9198 26.4591 17.4555 26.7646C18.9911 27.07 20.5829 26.9132 22.0295 26.314C23.4761 25.7149 24.7125 24.7002 25.5824 23.3983C26.4523 22.0964 26.9166 20.5658 26.9166 19C26.9141 16.9011 26.0792 14.889 24.5951 13.4049C23.111 11.9207 21.0988 11.0859 18.9999 11.0833ZM18.9999 23.75C18.0605 23.75 17.1421 23.4714 16.361 22.9495C15.5798 22.4275 14.971 21.6857 14.6115 20.8178C14.252 19.9498 14.1579 18.9947 14.3412 18.0733C14.5245 17.1519 14.9769 16.3055 15.6412 15.6412C16.3055 14.977 17.1518 14.5246 18.0732 14.3413C18.9947 14.158 19.9497 14.2521 20.8177 14.6116C21.6856 14.9711 22.4275 15.5799 22.9494 16.361C23.4713 17.1422 23.7499 18.0605 23.7499 19C23.7499 20.2598 23.2495 21.468 22.3587 22.3588C21.4679 23.2496 20.2597 23.75 18.9999 23.75Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_161_3653">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
