interface IconProps {
  color?: string;
}

const ScanIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0.631579C0.5 0.282771 0.782771 0 1.13158 0H7.95263V1.26316H1.76316V7.45263H0.5V0.631579ZM23.2368 1.26316H17.0474V0H23.8684C24.2172 0 24.5 0.282771 24.5 0.631579V7.45263H23.2368V1.26316ZM1.76316 22.7368V16.5474H0.5V23.3684C0.5 23.7172 0.782771 24 1.13158 24H7.95263V22.7368H1.76316ZM23.2368 22.7368V16.5474H24.5V23.3684C24.5 23.7172 24.2172 24 23.8684 24H17.0474V22.7368H23.2368Z"
        fill={color}
      />
    </svg>
  );
};

export default ScanIcon;
