import { PropsWithChildren, ReactElement, useState } from "react";
import { Stack } from "@mui/material";
import Accordion from "../Accordion";
import Text from "../../Text";
import StatusUpdate from "../../StatusUpdate";
import DestinationUpdateDialog from "../../Dialogs/DestinationsDialog/DestinationUpdateDialog";
import { Status, userActions, useUserDispatch, useUserState } from "../../../context/UserContext";
import StatusButton from "../../Buttons/StatusButton";
const defaultSummaryRenderer = (isOpen?: boolean) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
      <Text variant="h5" fontStyle="SemiBold">
        Status update
      </Text>
      <Text variant="body2" fontStyle="Regular">
        See {isOpen ? "less" : "more"}
      </Text>
    </Stack>
  );
};

const availableHospitals = ["Princess Alexandra Hospital(PAH)", "Greenslopes Private Hospital"];
export type StatusUpdateAccordionProps = PropsWithChildren<{
  noContainer?: boolean;
  renderSummary?: (isOpen?: boolean) => ReactElement;
}>;

const StatusUpdateAccordion = ({
  children,
  noContainer,
  renderSummary = defaultSummaryRenderer,
}: StatusUpdateAccordionProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const { status } = useUserState();
  const userDispatch = useUserDispatch();
  const closeStatusUpdateDialog = () => {
    userActions.setStatus(userDispatch, Status.OnScene);
  };

  return (
    <>
      <Accordion
        isOpen={isOpen}
        toggleIsOpen={() => setIsOpen(!isOpen)}
        noContainer={noContainer}
        summary={renderSummary(isOpen)}
      >
        <StatusUpdate>{children}</StatusUpdate>
      </Accordion>
      <DestinationUpdateDialog isOpen={(status as Status) === Status.DepartScene} onClose={closeStatusUpdateDialog}>
        {availableHospitals.map((s) => {
          return (
            <StatusButton
              key={s}
              onClick={() => {
                userActions.setStatus(userDispatch, Status.DepartScenePAH);
              }}
            >
              {s}
            </StatusButton>
          );
        })}
      </DestinationUpdateDialog>
    </>
  );
};

export default StatusUpdateAccordion;
