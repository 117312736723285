import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Tabs, Tab, Box, useTheme, Portal } from "@mui/material";
// mocks
import { mockIncidents } from "../../__MOCKS__/incidents";
// hooks
import useElementHeight from "../../hooks/useElementHeight";
import useScrollSpy from "../../hooks/useScrollSpy";
// context
import { incidentActions, useIncidentDispatch, useIncidentState } from "../../context/IncidentContext";
import { Status, userActions, useUserDispatch, useUserState } from "../../context/UserContext";
import { useHeaderRef } from "../../context/HeaderRefContext";
import { useStatusBarRef } from "../../context/StatusBarRefContext";
import { useFooterRef } from "../../context/FooterRefContext";
// icons
import RouteIcon from "../../icons/RouteIcon";
// components
import DetailsAccordion from "../../components/Accordions/DetailsAccordion";
import Button from "../../components/Buttons/Button";
import NotificationAccordion from "../../components/Accordions/NotificationAccordion";
import NotificationCard from "../../components/NotificationCard";
import StatusUpdateAccordion from "../../components/Accordions/StatusUpdateAccordion";
import StatusButton from "../../components/Buttons/StatusButton";
import StandaloneBadge from "../../components/StandaloneBadge";
import Text from "../../components/Text";

const IncidentAcknowledged = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showExtraTabs, setShowExtraTabs] = useState(false);
  const [showRouteBtn, setShowRouteBtn] = useState(true);
  const workflowTime = new Date();
  workflowTime.setHours(9, 20);
  const [notificaitonTime, setNotificationTime] = useState(workflowTime);

  const { allowedStatuses, status, previousStatus } = useUserState();
  const userDispatch = useUserDispatch();

  const incidentDispatch = useIncidentDispatch();

  const headerRef = useHeaderRef();
  const statusBarRef = useStatusBarRef();
  const footerRef = useFooterRef();

  const appHeaderEl = headerRef.current;
  const headerHeight = appHeaderEl?.offsetHeight ?? 0;
  const statusBarHeight = statusBarRef.current?.offsetHeight ?? 0;
  const footerHeight = footerRef.current?.offsetHeight ?? 0;
  const contentViewWindowHeight = window.innerHeight - headerHeight - statusBarHeight - footerHeight;

  const { ref: notificationAccordionRef, height: notificationAccordionHeight } = useElementHeight<HTMLDivElement>({
    delayMs: 550,
  });

  const sectionRefs: RefObject<HTMLElement>[] = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const activeSection = useScrollSpy({
    sectionElementRefs: showExtraTabs ? sectionRefs : [sectionRefs[0], sectionRefs[1]],
    offsetPx: -headerHeight - statusBarHeight - 16,
  });

  useEffect(() => {
    //Only show the extra 'Detail' tab responsively, when there are many notifications (or when the Detail Dialog can't fit into current screen)
    setShowExtraTabs(notificationAccordionHeight > 0.8 * contentViewWindowHeight);
  }, [notificationAccordionHeight, contentViewWindowHeight]);

  useEffect(() => {
    // sticky header is causing scroll position to not start at the top of the page, so scroll to top on first render
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      (status as Status) === Status.Activated ||
      (status as Status) === Status.Acknowledged ||
      ((status as Status) === Status.Delayed &&
        ((previousStatus as Status) === Status.Activated ||
          (previousStatus as Status) === Status.Acknowledged ||
          (previousStatus as Status) === Status.Mobile)) ||
      (status as Status) === Status.Mobile
    ) {
      setShowRouteBtn(true);
    } else {
      setShowRouteBtn(false);
    }

    if (
      (status as Status) === Status.Available &&
      ((previousStatus as Status) === Status.Mobile ||
        (previousStatus as Status) === Status.OffStretcher ||
        (previousStatus as Status) === Status.OffloadedButCleaning ||
        (previousStatus as Status) === Status.CompletingPaperwork ||
        (previousStatus as Status) === Status.DelayedInfectiousClean ||
        (previousStatus as Status) === Status.ReStocking)
    ) {
      incidentActions.incidentComplete(incidentDispatch);
      navigate("/", { replace: true });
    }
  }, [status, previousStatus, incidentDispatch, navigate]);

  useEffect(() => {
    if ((status as Status) === Status.Activated) {
      let activatedTime = new Date();
      activatedTime.setHours(9, 27);
      setNotificationTime(activatedTime);
    } else if ((status as Status) === Status.Mobile) {
      let mobileTime = new Date();
      mobileTime.setHours(9, 30);
      setNotificationTime(mobileTime);
    } else if ((status as Status) === Status.OnScene) {
      let onSceneTime = new Date();
      onSceneTime.setHours(9, 51);
      setNotificationTime(onSceneTime);
    } else if ((status as Status) === Status.DepartScenePAH) {
      let departSceneTime = new Date();
      departSceneTime.setHours(10, 58);
      setNotificationTime(departSceneTime);
    } else if ((status as Status) === Status.AtDestination) {
      let atDestTime = new Date();
      atDestTime.setHours(11, 15);
      setNotificationTime(atDestTime);
    } else if ((status as Status) === Status.Triaged) {
      let triagedTime = new Date();
      triagedTime.setHours(11, 21);
      setNotificationTime(triagedTime);
    } else if ((status as Status) === Status.Ramped) {
      let rampedTime = new Date();
      rampedTime.setHours(11, 40);
      setNotificationTime(rampedTime);
    }
  }, [status]);

  const scrollToSection = (index: number) => () => {
    const element = sectionRefs[index].current;
    if (!element) return;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerHeight - statusBarHeight;
    window.scrollTo({
      top: offsetPosition - 15,
      behavior: "smooth",
    });
  };

  return (
    <Stack spacing={2}>
      <Portal container={appHeaderEl}>
        <Tabs value={activeSection} variant="scrollable" allowScrollButtonsMobile>
          <Tab
            id="notificaiton"
            label="Notification"
            onClick={scrollToSection(0)}
            icon={<StandaloneBadge badgeContent={2} color="primary" />}
            iconPosition="end"
          />
          <Tab id="statusUpdate" label="Status update" onClick={scrollToSection(1)} />
          <Tab id="map" key="map" label="Map" onClick={() => navigate("/incident/map")} />
          {showExtraTabs && [<Tab id="details" key="details" label="Details" onClick={scrollToSection(3)} />]}
        </Tabs>
      </Portal>

      <section ref={sectionRefs[0]}>
        <NotificationAccordion
          ref={notificationAccordionRef}
          stickyNotification={
            <NotificationCard
              alertLevel="critical"
              title="Dispatcher"
              dateTime={notificaitonTime}
              message={
                <span>
                  <strong>Caution Notes:</strong> This patient has extensive cardiac history and is awaiting heart
                  transplant.
                </span>
              }
              icon="dispatch"
            />
          }
          notificationCount={2}
        >
          {Array(10)
            .fill([
              (key: any) => (
                <NotificationCard
                  alertLevel="normal"
                  title="Mike S."
                  dateTime={notificaitonTime}
                  message="Acknowledged incident 20012"
                  icon="avatar"
                  key={key}
                />
              ),
              (key: any) => (
                <NotificationCard
                  alertLevel="critical"
                  title="Dispatcher"
                  dateTime={notificaitonTime}
                  message={
                    <span>
                      <strong>Caution Notes:</strong> House on the left.
                    </span>
                  }
                  icon="dispatch"
                  key={key}
                />
              ),
            ])
            .flat()
            .map((C, index) => (
              <C key={index} />
            ))}
        </NotificationAccordion>
      </section>

      <section ref={sectionRefs[1]}>
        <StatusUpdateAccordion>
          {allowedStatuses.length < 1 ? (
            <Text>This incident has been resolved.</Text>
          ) : (
            allowedStatuses.map((s) => {
              return (
                <StatusButton key={s} onClick={() => userActions.setStatus(userDispatch, s)}>
                  {s}
                </StatusButton>
              );
            })
          )}
        </StatusUpdateAccordion>
      </section>

      {showRouteBtn ? (
        <section ref={sectionRefs[2]}>
          <Button
            color="blue"
            endIcon={
              <Box width="24px" height="24px">
                <RouteIcon color={theme.palette.blue.contrastText} />
              </Box>
            }
            fullWidth
            onClick={() => navigate("/incident/map")}
          >
            Route to incident
          </Button>
        </section>
      ) : undefined}

      <section ref={sectionRefs[3]}>
        <DetailsAccordion>{mockIncidents[0].message}</DetailsAccordion>
      </section>
    </Stack>
  );
};

const IncidentDetails = () => {
  const navigate = useNavigate();

  const userDispatch = useUserDispatch();
  const { status } = useUserState();

  const { incident } = useIncidentState();
  const incidentDispatch = useIncidentDispatch();

  const acknowledgeIncident = () => {
    incidentActions.acknowledgeIncident(incidentDispatch);
    userActions.setStatus(userDispatch, Status.Mobile);
  };

  useEffect(() => {
    if (!incident) {
      navigate("/");
    }
  }, [incident, navigate]);

  useEffect(() => {
    if (status !== Status.Activated && status !== Status.Acknowledged) {
      userActions.setStatus(userDispatch, Status.Activated);
    }
  }, [status, userDispatch]);

  useEffect(() => {
    // scroll position is carried over from prior view, reset it when loading this view
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack spacing={2}>
      <DetailsAccordion>{mockIncidents[0].message}</DetailsAccordion>
      <Button color="pink" fullWidth onClick={acknowledgeIncident}>
        Mobile
      </Button>
    </Stack>
  );
};

const Incident = () => {
  const { acknowledged } = useIncidentState();
  return acknowledged ? <IncidentAcknowledged /> : <IncidentDetails />;
};

export default Incident;
