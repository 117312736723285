import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Routes, Navigate, Route, useLocation, useNavigate } from "react-router-dom";
// context
import { useAuthState } from "./context/AuthContext";
import { ReadyState, useVehicleState } from "./context/VehicleContext";
import { Status, statusWarningMessages, userActions, useUserDispatch, useUserState } from "./context/UserContext";
import { incidentActions, useIncidentDispatch, useIncidentState } from "./context/IncidentContext";
import { useHeaderRef } from "./context/HeaderRefContext";
import { useStatusBarRef } from "./context/StatusBarRefContext";
import { useFooterRef } from "./context/FooterRefContext";
// layout
import AppLayout from "./layouts/AppLayout";
import UnauthenticatedLayout from "./layouts/UnauthenticatedLayout";
// components
import AppHeader from "./components/Headers/AppHeader";
import AppFooter, { MenuItem } from "./components/Footers/AppFooter";
import IncidentHeader from "./components/Headers/IncidentHeader";
import StatusBar from "./components/StatusBar";
import StatusUpdateDialog from "./components/Dialogs/StatusUpdateDialog";
import StatusButton from "./components/Buttons/StatusButton";
// Pages
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import VehicleLogin from "./pages/VehicleLogin";
import CrewAndVehicle from "./pages/CrewAndVehicle";
import Incident from "./pages/Incident";
import NotFound from "./pages/NotFound";
import RouteToIncident from "./pages/Incident/Map";
import History from "./pages/History";

const UnauthenticatedApp = () => {
  return (
    <UnauthenticatedLayout>
      <UnauthenticatedLayout.Content>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </UnauthenticatedLayout.Content>
    </UnauthenticatedLayout>
  );
};

const AuthenticatedApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { readyState } = useVehicleState();

  const [statusUpdateDialogIsOpen, setStatusUpdateDialogIsOpen] = useState(false);
  const openStatusUpdateDialog = () => {
    if (readyState === ReadyState.LOGGED_IN) {
      setStatusUpdateDialogIsOpen(true);
    }
  };
  const closeStatusUpdateDialog = () => setStatusUpdateDialogIsOpen(false);

  const { status, allowedStatuses } = useUserState();
  const userDispatch = useUserDispatch();
  const setStatusIdle = () => userActions.setStatus(userDispatch, Status.OffDuty);

  const { incident } = useIncidentState();
  const incidentDispatch = useIncidentDispatch();
  const clearIncident = () => {
    if (location.pathname.includes("/incident") && location.pathname.includes("/map")) {
      navigate(-1); // or navigate("/incident");
    } else {
      incidentActions.clearIncident(incidentDispatch);
      setStatusIdle();
    }
  };

  const headerRef = useHeaderRef();
  const statusBarRef = useStatusBarRef();
  const footerRef = useFooterRef();
  const getInitialTime = () => {
    if (status === Status.Triaged || status === Status.Ramped) {
      return {
        minutes: 14,
        seconds: 57,
      };
    } else if (status === Status.OnMealBreak) {
      return {
        minutes: 24,
        seconds: 57,
      };
    } else if (status === Status.AtDestination) {
      return {
        minutes: 4,
        seconds: 57,
      };
    }
  };

  const getOverTime = () => {
    if (status === Status.Triaged || status === Status.Ramped) {
      return {
        minutes: 15,
        seconds: 0,
      };
    } else if (status === Status.OnMealBreak) {
      return {
        minutes: 25,
        seconds: 0,
      };
    } else if (status === Status.AtDestination) {
      return {
        minutes: 5,
        seconds: 0,
      };
    }
  };

  const getSwitchStatus = () => {
    if (status === Status.OnMealBreak) {
      return {
        minutes: 25,
        seconds: 4,
      };
    }
  };
  return (
    <AppLayout>
      <Stack>
        <Stack position={!!incident ? "sticky" : undefined} top={0} zIndex={2}>
          <AppLayout.Header ref={headerRef}>
            {location.pathname === "/crew-and-vehicle" ? (
              <AppHeader
                showMealBreakStatus={readyState === ReadyState.LOGGED_IN}
                isOnMealBreak={status === Status.OnMealBreak}
                readyStatus={readyState}
                endMealBreak={setStatusIdle}
              />
            ) : incident ? (
              <IncidentHeader title={incident.title} address={incident.address} clearIncident={clearIncident} />
            ) : (
              // For demo, meal break status icon is shown when user is ready to accept jobs
              // For demo, to end a meal break the user can click the icon
              <AppHeader
                showMealBreakStatus={readyState === ReadyState.LOGGED_IN}
                isOnMealBreak={status === Status.OnMealBreak}
                readyStatus={readyState}
                endMealBreak={setStatusIdle}
              />
            )}
          </AppLayout.Header>
          <AppLayout.StatusBar ref={statusBarRef}>
            {
              <StatusBar
                status={status}
                warningMessage={statusWarningMessages[status]}
                onClickStatus={openStatusUpdateDialog}
                initialTime={getInitialTime()}
                overtime={getOverTime()}
                switchStatus={getSwitchStatus()}
              />
            }
          </AppLayout.StatusBar>
        </Stack>

        <AppLayout.Content>
          <Routes>
            <Route path="/crew-and-vehicle" element={<CrewAndVehicle />} />
            <Route path="/history" element={<History />} />
            <Route path="/incident" element={<Incident />} />
            <Route path="/incident/map" element={<RouteToIncident />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/vehicle-login" element={<VehicleLogin />} />
            <Route path="/login" element={<Navigate to="/" replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppLayout.Content>
      </Stack>

      <AppLayout.Footer ref={footerRef}>
        <AppFooter isReady={readyState === ReadyState.LOGGED_IN} selectedItem={location.pathname as MenuItem} />
      </AppLayout.Footer>

      <StatusUpdateDialog isOpen={statusUpdateDialogIsOpen} onClose={closeStatusUpdateDialog}>
        {allowedStatuses.map((s) => {
          return (
            <StatusButton
              key={s}
              onClick={() => {
                userActions.setStatus(userDispatch, s);
                closeStatusUpdateDialog();
              }}
            >
              {s}
            </StatusButton>
          );
        })}
      </StatusUpdateDialog>
    </AppLayout>
  );
};

const App = () => {
  const { isAuthenticated } = useAuthState();
  const location = useLocation();

  useEffect(() => {
    if (window.swUpdateReady) {
      window.swUpdateReady = false;
      window.stop();
      window.location.reload();
    }
  }, [location]);

  return isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default App;
