interface IconProps {
  color?: string;
}

const ArrowLeftIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 21 19" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 7.95833H4.5L9.435 2.88625C9.57559 2.74293 9.68718 2.57242 9.76334 2.38456C9.83949 2.19669 9.8787 1.99519 9.8787 1.79167C9.8787 1.58815 9.83949 1.38664 9.76334 1.19878C9.68718 1.01091 9.57559 0.840402 9.435 0.697084C9.15396 0.409947 8.77378 0.248779 8.3775 0.248779C7.98122 0.248779 7.60104 0.409947 7.32 0.697084L0.885 7.32625C0.321619 7.90183 0.00334918 8.68357 0 9.5C0.00729988 10.3111 0.325267 11.0865 0.885 11.6583L7.32 18.2875C7.45986 18.4302 7.6257 18.5433 7.80806 18.6201C7.99041 18.697 8.18571 18.7362 8.3828 18.7354C8.5799 18.7347 8.77492 18.6941 8.95675 18.6159C9.13857 18.5378 9.30363 18.4235 9.4425 18.2798C9.58137 18.136 9.69134 17.9656 9.76612 17.7782C9.8409 17.5908 9.87903 17.39 9.87833 17.1875C9.87764 16.9849 9.83813 16.7845 9.76206 16.5976C9.68599 16.4107 9.57486 16.2411 9.435 16.0983L4.5 11.0417H19.5C19.8978 11.0417 20.2794 10.8792 20.5607 10.5901C20.842 10.301 21 9.90888 21 9.5C21 9.09113 20.842 8.699 20.5607 8.40988C20.2794 8.12076 19.8978 7.95833 19.5 7.95833Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
