import { IconButton, Paper, Stack, styled } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { PropsWithChildren } from "react";
import MinusIcon from "../../../icons/MinusIcon";
// icons
import SendIcon from "../../../icons/SendIcon";
import SmileIcon from "../../../icons/SmileIcon";
// components
import TextField from "../../FormInputs/TextField";
import Text from "../../Text";

const ChatContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 2, 2),
}));

export type ChatMessengerProps = PropsWithChildren<{
  hideChat: () => void;
}>;

const ChatMessenger = ({ children, hideChat }: ChatMessengerProps) => {
  const formik = useFormik<{ message: string }>({
    initialValues: {
      message: "",
    },
    onSubmit: (_, { setFieldValue }) => {
      // clear text
      setFieldValue("message", "");
    },
  });

  return (
    <Paper variant="outlined">
      <ChatContainer>
        <FormikProvider value={formik}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text variant="h5" fontStyle="SemiBold">
                  Messenger
                </Text>
                <span>
                  <Stack flexDirection="row" justifyContent="flex-end">
                    <IconButton aria-label="hide" onClick={hideChat} sx={{ height: "32px", width: "32px", padding: 1 }}>
                      <MinusIcon />
                    </IconButton>
                  </Stack>
                </span>
              </Stack>

              <Paper variant="outlined">
                <Stack spacing={0.5} padding={1}>
                  {children}
                </Stack>
              </Paper>

              <Stack>
                <TextField
                  name="message"
                  placeholder="Write a message..."
                  endAdornment={
                    <Stack direction="row">
                      <IconButton aria-label="emoji">
                        <Stack height="24px" width="24px" padding={0.5}>
                          <SmileIcon />
                        </Stack>
                      </IconButton>

                      <IconButton aria-label="send" disabled={formik.values.message === ""} type="submit">
                        <Stack height="24px" width="24px" padding={0.5}>
                          <SendIcon />
                        </Stack>
                      </IconButton>
                    </Stack>
                  }
                  fullWidth
                  multiline
                />
              </Stack>
            </Stack>
          </form>
        </FormikProvider>
      </ChatContainer>
    </Paper>
  );
};

export default ChatMessenger;
