import { Stack, AppBar, styled } from "@mui/material";
import BackButton from "../../Buttons/BackButton";
import Text from "../../Text";

const Root = styled(AppBar)(() => ({
  height: "94px",
  position: "relative",
}));

const Container = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  padding: theme.spacing(2),
  width: "100%",
}));

export interface IncidentHeaderProps {
  title: string;
  address: string;
  clearIncident?: () => void;
}

const IncidentHeader = ({ title, address, clearIncident }: IncidentHeaderProps) => {
  return (
    <Root>
      <Container direction="row" spacing={1.5} alignItems="center">
        <BackButton goBack={clearIncident} />
        <Stack>
          <Text fontStyle="SemiBold" variant="h5" data-testid="incident-header-title">
            {title}
          </Text>
          <Text fontStyle="Regular" variant="caption" data-testid="incident-header-caption">
            {address}
          </Text>
        </Stack>
      </Container>
    </Root>
  );
};

export default IncidentHeader;
