import { createContext, PropsWithChildren, RefObject, useContext, useRef } from "react";

const FooterRefContext = createContext<RefObject<HTMLElement>>(undefined!);

const FooterRefProvider = ({ children }: PropsWithChildren<unknown>) => {
  const ref = useRef<HTMLElement>(null);
  return <FooterRefContext.Provider value={ref}>{children}</FooterRefContext.Provider>;
};

const useFooterRef = () => {
  const context = useContext(FooterRefContext);
  if (context === undefined) {
    throw new Error("useFooterRef must be within FooterRefProvider");
  }
  return context;
};

export { FooterRefProvider, useFooterRef };
