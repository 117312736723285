import { RefObject, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
// mocks
import { mockIncidents } from "../../__MOCKS__/incidents";
// context
import { useIncidentState } from "../../context/IncidentContext";
// components
import TimesIncidentAccordion from "../../components/Accordions/TimesIncidentAccordion";

const IncidentTimes = () => {
  const sectionRefs: RefObject<HTMLElement>[] = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack spacing={2}>
      <section ref={sectionRefs[0]}>
        <TimesIncidentAccordion incidentNo={mockIncidents[0].incidentNo}>
          {mockIncidents[0].statusTimes}
        </TimesIncidentAccordion>
      </section>
      <section ref={sectionRefs[1]}>
        <TimesIncidentAccordion incidentNo={mockIncidents[1].incidentNo}>
          {mockIncidents[1].statusTimes}
        </TimesIncidentAccordion>
      </section>
    </Stack>
  );
};

const NoYetCompletedIncidentTimes = () => {
  const sectionRefs: RefObject<HTMLElement>[] = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack spacing={2}>
      <section ref={sectionRefs[0]}>
        <TimesIncidentAccordion incidentNo={mockIncidents[1].incidentNo}>
          {mockIncidents[1].statusTimes}
        </TimesIncidentAccordion>
      </section>
    </Stack>
  );
};

const History = () => {
  const { complete } = useIncidentState();
  return complete ? <IncidentTimes /> : <NoYetCompletedIncidentTimes />;
};

export default History;
