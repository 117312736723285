import { useNavigate } from "react-router-dom";
import { Divider, MenuItem, Stack } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { CrewMember, VehicleInfo } from "../../../types";
import { mockCrewMembers } from "../../../__MOCKS__/crew";
import { useVehicleState } from "../../../context/VehicleContext";
// components
import Text from "../../Text";
import CrewMemberSelect from "../../FormInputs/CrewMemberSelect";
import Select from "../../FormInputs/Select";
import ChangeButton from "../../Buttons/ChangeButton";

interface CrewAndVehicleFormSchema {
  crew: {
    members: string[];
  };
  vehicle: {
    code: string;
    portableDevices: string;
  };
}

interface CrewAndVehicleEditFormProps {
  vehicleInfo: VehicleInfo;
  crewMembers: CrewMember[];
}

const CrewAndVehicleEditForm = ({ vehicleInfo, crewMembers }: CrewAndVehicleEditFormProps) => {
  const formik = useFormik<CrewAndVehicleFormSchema>({
    initialValues: {
      crew: {
        members: crewMembers.map(({ id }) => id),
      },
      vehicle: {
        code: vehicleInfo.code,
        portableDevices: vehicleInfo.portableDevices,
      },
    },
    onSubmit: () => {
      // no submit logic required
    },
  });

  const selectedCrewMembers = mockCrewMembers.filter(({ id }) =>
    formik.values.crew.members.find((memberId) => memberId === id),
  );

  const crewMemberOptions = mockCrewMembers.map(({ id, name }) => (
    <MenuItem
      key={id}
      value={id}
      sx={{ display: !!selectedCrewMembers.find((member) => member.id === id) ? "none" : undefined }}
    >
      {name}
    </MenuItem>
  ));

  return (
    <FormikProvider value={formik}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <Stack spacing={3}>
            <Text variant="h5" fontStyle="SemiBold">
              Assigned Crew
            </Text>
            {selectedCrewMembers.map(({ id }, index) => (
              <CrewMemberSelect key={id} index={index} fieldName="crew.members" changeOnly>
                {crewMemberOptions}
              </CrewMemberSelect>
            ))}
            <CrewMemberSelect index={formik.values.crew.members.length} fieldName="crew.members">
              {crewMemberOptions}
            </CrewMemberSelect>
          </Stack>

          <Divider />

          <Stack spacing={3}>
            <Text variant="h5" fontStyle="SemiBold">
              Assigned vehicle
            </Text>

            <Select name="vehicle.code" readOnly endAdornment={<ChangeButton />}>
              <MenuItem value={formik.values.vehicle.code}>Vehicle {formik.values.vehicle.code}</MenuItem>
            </Select>
          </Stack>

          <Divider />

          <Stack spacing={3}>
            <Text variant="h5" fontStyle="SemiBold">
              Assigned devices
            </Text>

            <Select name="vehicle.portableDevices" readOnly endAdornment={<ChangeButton />}>
              <MenuItem value={formik.values.vehicle.portableDevices}>{formik.values.vehicle.portableDevices}</MenuItem>
            </Select>
          </Stack>
        </Stack>
      </form>
    </FormikProvider>
  );
};

const CrewAndVehicleForm = () => {
  const navigate = useNavigate();
  const { vehicleInfo, crewMembers } = useVehicleState();

  if (!vehicleInfo || !crewMembers) {
    navigate("/", { replace: true });
    return null;
  }

  return <CrewAndVehicleEditForm vehicleInfo={vehicleInfo} crewMembers={crewMembers} />;
};

export default CrewAndVehicleForm;
