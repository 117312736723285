import { IconButton, Stack, useTheme } from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import CloseIcon from "../../../icons/CloseIcon";
// context
import { vehicleActions, useVehicleDispatch } from "../../../context/VehicleContext";
import { authActions, useAuthDispatch } from "../../../context/AuthContext";
import { incidentActions, useIncidentDispatch } from "../../../context/IncidentContext";
import { Status, userActions, useUserDispatch } from "../../../context/UserContext";
// components
import Dialog from "../Dialog";
import CheckboxGroup, { CheckboxOption } from "../../FormInputs/CheckboxGroup";
import Button from "../../Buttons/Button";

const LOGOFF_MDT = "mdt";
const LOGOFF_MDT_APP = "mdt-app";

const logoffOptions: CheckboxOption[] = [
  {
    id: LOGOFF_MDT,
    label: "Log off vehicle only",
  },
  {
    id: LOGOFF_MDT_APP,
    label: "End shift",
  },
];

export interface LogoffDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const LogoffDialogBase = ({ isOpen, onClose }: LogoffDialogProps) => {
  const theme = useTheme();
  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();
  const vehicleDispatch = useVehicleDispatch();
  const incidentDispatch = useIncidentDispatch();

  const formik = useFormik({
    initialValues: {
      selection: null,
    },
    onSubmit: ({ selection }) => {
      if (selection === LOGOFF_MDT_APP) {
        authActions.logoff(authDispatch);
      }
      vehicleActions.logoutOfVehicle(vehicleDispatch);
      incidentActions.clearIncident(incidentDispatch);
      userActions.setStatus(userDispatch, Status.OffDuty);
      onClose && onClose();
    },
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Stack flexDirection="row" justifyContent="flex-end">
          <span>
            <IconButton aria-label="close" onClick={onClose} sx={{ height: "32px", width: "32px", padding: 1 }}>
              <CloseIcon color={theme.palette.common.white} />
            </IconButton>
          </span>
        </Stack>
      }
    >
      <FormikProvider value={formik}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <CheckboxGroup
              label="Please select to log off:"
              name="selection"
              options={logoffOptions}
              variant="standout"
            />
            <Stack justifyContent="space-between" direction="row" spacing={2}>
              <Button color="neutral" outlined onClick={onClose}>
                Cancel
              </Button>
              <Button color="blue" disabled={!formik.values.selection} type="submit">
                Log off
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

const LogoffDialog = (props: LogoffDialogProps) => {
  const { isOpen } = props;
  return isOpen ? <LogoffDialogBase {...props} /> : null;
};

export default LogoffDialog;
