import { Divider, Stack } from "@mui/material";
import { getFieldName, InspectionField, VehicleLoginFormField } from "./schema";
// components
import CheckboxGroup, { CheckboxOption } from "../../FormInputs/CheckboxGroup";
import StepTitle from "./StepTitle";
import TextField from "../../FormInputs/TextField";
import CheckboxStepLabel from "./CheckboxStepLabel";
import CameraButton from "../../Buttons/CameraButton";
import TakePhotoDialog from "../../Dialogs/TakePhotoDialog";
import { useState } from "react";

export interface InspectionProps {
  goBack: () => void;
}

const binaryOptions: CheckboxOption[] = [
  {
    id: true,
    label: "Yes",
  },
  {
    id: false,
    label: "No",
  },
];

const Inspection = ({ goBack }: InspectionProps) => {
  const [takeVehiclePhotoOpen, setTakeVehiclePhotoOpen] = useState(false);
  const [vehiclePhoto, setVehiclePhoto] = useState<string | undefined>();
  const showTakeVehiclePhotoDialog = () => setTakeVehiclePhotoOpen(true);
  const hideTakeVehiclePhotoDialog = (photoTaken?: string) => {
    setTakeVehiclePhotoOpen(false);
    if (photoTaken) {
      setVehiclePhoto(photoTaken);
    }
  };

  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <StepTitle goBack={goBack}>Step 3: Vehicle Inspection</StepTitle>
        <Divider />
      </Stack>

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={1} label="Is the vehicle serviceable?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.Inspection,
            field: InspectionField.Vehicle,
            nestedField: "selection",
          })}
          options={binaryOptions}
        />

        {vehiclePhoto ? <img src={vehiclePhoto} alt="Taken From Camera" width="100%" height="auto" /> : null}
        <Stack direction="row" spacing={2}>
          <TextField
            name={getFieldName({
              prefix: VehicleLoginFormField.Inspection,
              field: InspectionField.Vehicle,
              nestedField: "comments",
            })}
            placeholder="Leave a comment"
            fullWidth
          />
          <CameraButton onClick={showTakeVehiclePhotoDialog} />
          <TakePhotoDialog isOpen={takeVehiclePhotoOpen} onClose={hideTakeVehiclePhotoDialog} />
        </Stack>
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={2} label="Are vehicle emergency devices serviceable?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.Inspection,
            field: InspectionField.EmergencyDevices,
            nestedField: "selection",
          })}
          options={binaryOptions}
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.Inspection,
            field: InspectionField.EmergencyDevices,
            nestedField: "comments",
          })}
          placeholder="Leave a comment"
        />
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={3} label="Is stretcher/chair present and serviceable?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.Inspection,
            field: InspectionField.StretcherChair,
            nestedField: "selection",
          })}
          options={binaryOptions}
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.Inspection,
            field: InspectionField.StretcherChair,
            nestedField: "comments",
          })}
          placeholder="Leave a comment"
        />
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={4} label="Are all straps and boards present and serviceable?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.Inspection,
            field: InspectionField.StrapsAndBoards,
            nestedField: "selection",
          })}
          options={binaryOptions}
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.Inspection,
            field: InspectionField.StrapsAndBoards,
            nestedField: "comments",
          })}
          placeholder="Leave a comment"
        />
      </Stack>

      <Divider />
    </Stack>
  );
};

export default Inspection;
