import { ReactNode } from "react";
import { FormControlLabel, Checkbox as MuiCheckbox, FormGroup, FormControl, FormLabel } from "@mui/material";
import { useField } from "formik";

export type CheckboxOption = { id: string | number | boolean; label: string };

export interface CheckboxGroupProps {
  name: string;
  options: CheckboxOption[];
  label?: string | ReactNode;
  disabled?: boolean;
  multiselect?: boolean;
  variant?: "default" | "standout";
}

const CheckboxGroup = ({ name, options, label, disabled, multiselect, variant = "default" }: CheckboxGroupProps) => {
  const [field, _, helpers] = useField(name);

  const handleChange = (id: string | number | boolean, checked: boolean) => {
    if (multiselect) {
      helpers.setValue(checked ? [...field.value, id] : (field.value as []).filter((value) => value !== id));
    } else {
      helpers.setValue(checked ? id : null);
    }
  };

  return (
    <FormControl disabled={disabled}>
      <FormLabel component="legend" classes={{ root: variant }}>
        {label}
      </FormLabel>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={`${option.id}`}
            label={option.label}
            classes={{ label: variant }}
            control={
              <MuiCheckbox
                color="default"
                checked={multiselect ? (field.value as []).some((v) => v === option.id) : field.value === option.id}
                onChange={(_, checked) => handleChange(option.id, checked)}
                sx={{ marginLeft: label ? 4 : undefined }}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
