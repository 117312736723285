interface IconProps {
  color?: string;
}

const CloseIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 28 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.6582 0.341828C27.4394 0.123112 27.1427 0.000244141 26.8333 0.000244141C26.524 0.000244141 26.2273 0.123112 26.0085 0.341828L14 12.3503L1.9915 0.341828C1.77271 0.123112 1.47602 0.000244141 1.16666 0.000244141C0.857304 0.000244141 0.560611 0.123112 0.341828 0.341828C0.123112 0.560611 0.000244141 0.857304 0.000244141 1.16666C0.000244141 1.47602 0.123112 1.77271 0.341828 1.9915L12.3503 14L0.341828 26.0085C0.123112 26.2273 0.000244141 26.524 0.000244141 26.8333C0.000244141 27.1427 0.123112 27.4394 0.341828 27.6582C0.560611 27.8769 0.857304 27.9997 1.16666 27.9997C1.47602 27.9997 1.77271 27.8769 1.9915 27.6582L14 15.6497L26.0085 27.6582C26.2273 27.8769 26.524 27.9997 26.8333 27.9997C27.1427 27.9997 27.4394 27.8769 27.6582 27.6582C27.8769 27.4394 27.9997 27.1427 27.9997 26.8333C27.9997 26.524 27.8769 26.2273 27.6582 26.0085L15.6497 14L27.6582 1.9915C27.8769 1.77271 27.9997 1.47602 27.9997 1.16666C27.9997 0.857304 27.8769 0.560611 27.6582 0.341828Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
