import { Button, styled } from "@mui/material";
import { PropsWithChildren } from "react";

const Root = styled(Button)(({ theme }) => ({
  display: "inline-block",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: "10px 15px",
  textTransform: "none",
  color: theme.palette.primary[10],
  fontWeight: theme.typography.fontWeightMedium,
}));

export type StatusButtonProps = PropsWithChildren<{
  onClick?: () => void;
}>;

const StatusButton = ({ children, onClick }: StatusButtonProps) => {
  return <Root onClick={onClick}>{children}</Root>;
};

export default StatusButton;
