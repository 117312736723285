interface IconProps {
  color?: string;
}

const ScanWithLineIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 28 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 0.631579C2.5 0.282771 2.78277 0 3.13158 0H9.95263V1.26316H3.76316V7.45263H2.5V0.631579ZM25.2368 1.26316H19.0474V0H25.8684C26.2172 0 26.5 0.282771 26.5 0.631579V7.45263H25.2368V1.26316ZM3.76316 22.7368V16.5474H2.5V23.3684C2.5 23.7172 2.78277 24 3.13158 24H9.95263V22.7368H3.76316ZM25.2368 22.7368V16.5474H26.5V23.3684C26.5 23.7172 26.2172 24 25.8684 24H19.0474V22.7368H25.2368Z"
        fill={color}
      />
      <line y1="11.25" x2="28" y2="11.25" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default ScanWithLineIcon;
