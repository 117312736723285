import { MenuItem, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { VehicleLoginFormField, getFieldName, CrewField, VehicleLoginFormSchema } from "./schema";
import { mockCrewMembers } from "../../../__MOCKS__/crew";
// components
import CrewMemberSelect, { TypeOfCrewsPlaceholders } from "../../FormInputs/CrewMemberSelect";
import StepTitle from "./StepTitle";

export interface VehicleProps {
  goBack: () => void;
}

const Vehicle = ({ goBack }: VehicleProps) => {
  const formik = useFormikContext<VehicleLoginFormSchema>();
  const fieldName = getFieldName({
    prefix: VehicleLoginFormField.Crew,
    field: CrewField.Members,
  });

  const selectedCrewMembers = mockCrewMembers.filter(({ id }) =>
    formik.values.crew[CrewField.Members].find((memberId) => memberId === id),
  );
  const crewMemberOptions = mockCrewMembers.map(({ id, name }) => (
    <MenuItem
      key={id}
      value={id}
      sx={{ display: !!selectedCrewMembers.find((member) => member.id === id) ? "none" : undefined }}
    >
      {name}
    </MenuItem>
  ));

  return (
    <Stack spacing={1}>
      <StepTitle goBack={goBack}>Step 2: Assign Crew</StepTitle>

      <Stack spacing={2}>
        <CrewMemberSelect index={0} fieldName={fieldName} readOnly>
          {crewMemberOptions}
        </CrewMemberSelect>
        <CrewMemberSelect index={1} fieldName={fieldName}>
          {crewMemberOptions}
        </CrewMemberSelect>
        <CrewMemberSelect index={2} fieldName={fieldName}>
          {crewMemberOptions}
        </CrewMemberSelect>
        <CrewMemberSelect index={3} fieldName={fieldName} typeOfCrew={TypeOfCrewsPlaceholders.Observer}>
          {crewMemberOptions}
        </CrewMemberSelect>
        <CrewMemberSelect index={4} fieldName={fieldName} typeOfCrew={TypeOfCrewsPlaceholders.Observer}>
          {crewMemberOptions}
        </CrewMemberSelect>
      </Stack>
    </Stack>
  );
};

export default Vehicle;
