import { createContext, PropsWithChildren, RefObject, useContext, useRef } from "react";

const HeaderRefContext = createContext<RefObject<HTMLElement>>(undefined!);

const HeaderRefProvider = ({ children }: PropsWithChildren<unknown>) => {
  const ref = useRef<HTMLElement>(null);
  return <HeaderRefContext.Provider value={ref}>{children}</HeaderRefContext.Provider>;
};

const useHeaderRef = () => {
  const context = useContext(HeaderRefContext);
  if (context === undefined) {
    throw new Error("useHeaderRef must be within HeaderRefProvider");
  }
  return context;
};

export { HeaderRefProvider, useHeaderRef };
