import { PropsWithChildren, ReactNode, useState } from "react";
import { Box, Stack } from "@mui/material";
import Accordion from "../Accordion";
import LunchIcon from "../../../icons/LunchIcon";
import Button from "../../Buttons/Button";
import Text from "../../Text";
import GeneralStatusUpdateDialog from "../../Dialogs/GeneralStatusUpdateDialog";
import { useIncidentState } from "../../../context/IncidentContext";

export type GeneralAccordionProps = PropsWithChildren<{
  showMealBreakButton?: boolean;
  startMealBreak?: () => void;
  stickyNotification?: ReactNode;
  isOpenByDefault?: boolean;
}>;

const GeneralAccordion = ({
  showMealBreakButton = false,
  startMealBreak,
  stickyNotification,
  isOpenByDefault = false,
  children,
}: GeneralAccordionProps) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState(isOpenByDefault);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const { complete } = useIncidentState();

  const openDialog = () => setDialogIsOpen(true);
  const closeDialog = () => setDialogIsOpen(false);

  const buttons: ReactNode[] = complete
    ? []
    : [
        <Button key="GeneralStatusUpdate" color="blue" outlined fullWidth onClick={openDialog}>
          General Status Update
        </Button>,
      ];

  if (showMealBreakButton) {
    buttons.push(
      <Button
        key="OnMealBreak"
        endIcon={
          <Box width="24px" height="24px">
            <LunchIcon />
          </Box>
        }
        onClick={startMealBreak ?? undefined}
      >
        On Meal Break
      </Button>,
    );
  }

  return (
    <>
      <GeneralStatusUpdateDialog isOpen={dialogIsOpen} onClose={closeDialog} />
      <Accordion
        isOpen={accordionIsOpen}
        toggleIsOpen={() => setAccordionIsOpen(!accordionIsOpen)}
        summary={
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Text variant="h5" fontStyle="SemiBold">
              General
            </Text>
            <Text variant="body2" fontStyle="Regular">
              {accordionIsOpen ? "Hide" : "Show"} History
            </Text>
          </Stack>
        }
        stickyContent={stickyNotification}
        buttons={buttons}
      >
        {children}
      </Accordion>
    </>
  );
};

export default GeneralAccordion;
