import { Paper, PaperProps, Stack, styled, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren, ReactElement, ReactNode } from "react";
import Avatar from "../Avatars/Avatar";
import DispatchIcon from "../../icons/DispatchIcon";
import SpeakerIcon from "../../icons/SpeakerIcon";
import { formatAMPM } from "../../utils/time";
import Text from "../Text";

export type AlertLevel = "system" | "normal" | "critical";

export type NotificationCardProps = PropsWithChildren<{
  alertLevel: AlertLevel;
  title: string;
  message: string | ReactNode;
  dateTime: Date;
  icon?: "speaker" | "dispatch" | "avatar" | ReactNode;
}>;

interface IconProps {
  icon?: NotificationCardProps["icon"];
  alertLevel: AlertLevel;
}

const Icon = ({ icon, alertLevel }: IconProps): ReactElement => {
  const theme = useTheme();
  const color = {
    system: theme.palette.secondary.main,
    normal: theme.palette.primary.main,
    critical: theme.palette.pink.main,
  }[alertLevel];

  switch (icon) {
    case "speaker":
      return <SpeakerIcon color={color} />;
    case "dispatch":
      return <DispatchIcon color={color} />;
    case "avatar":
      return <Avatar size="30px" borderColor={({ palette }) => palette.grey[500]} />;
    default:
      return <>{icon}</>;
  }
};

const HeaderText = ({ children }: PropsWithChildren<unknown>) => (
  <Text variant="caption" fontStyle="Regular">
    {children}
  </Text>
);

const Header = ({
  title,
  dateTime,
  alertLevel,
  icon,
  ...props
}: Pick<NotificationCardProps, "title" | "dateTime" | "alertLevel" | "icon">) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" {...props}>
      <Stack direction="row" alignItems="center">
        <Box width="26px" height="26px" marginRight={2}>
          <Icon icon={icon} alertLevel={alertLevel} />
        </Box>
        <HeaderText>{title}</HeaderText>
      </Stack>
      <Stack>
        <HeaderText>{formatAMPM(dateTime)}</HeaderText>
      </Stack>
    </Stack>
  );
};

const MessageText = ({ children, ...props }: PropsWithChildren<unknown>) => {
  return (
    <Text variant="body2" fontStyle="Regular" color={({ palette }) => palette.grey[200]} {...props}>
      <span>{children}</span>
    </Text>
  );
};

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "alertLevel",
})<PaperProps & { alertLevel: AlertLevel }>(({ theme, alertLevel }) => ({
  padding: theme.spacing(2),
  borderColor: {
    system: theme.palette.green[60],
    normal: theme.palette.blue[60],
    critical: theme.palette.pink[60],
  }[alertLevel],
}));

const NotificationCard = ({ alertLevel, title, dateTime, message, icon, children }: NotificationCardProps) => {
  return (
    <StyledPaper variant="outlined" alertLevel={alertLevel}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Header
            title={title}
            dateTime={dateTime}
            alertLevel={alertLevel}
            icon={icon}
            data-testid="notification-card-header"
          />
          <MessageText data-testid="notification-card-message">{message}</MessageText>
        </Stack>
        {children && <MessageText data-testid="notification-card-children">{children}</MessageText>}
      </Stack>
    </StyledPaper>
  );
};

export default NotificationCard;
