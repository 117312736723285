import { PropsWithChildren, ReactNode, forwardRef } from "react";
import { Stack, Box, styled, Theme, Collapse, StackProps } from "@mui/material";
import CaretUpIcon from "../../../icons/CaretUpIcon";
import { BoxProps, SxProps } from "@mui/system";

export type AccordionProps = PropsWithChildren<{
  isOpen?: boolean;
  toggleIsOpen?: () => void;
  buttons?: ReactNode[];
  summary?: ReactNode;
  footer?: ReactNode;
  stickyContent?: ReactNode;
  noContainer?: boolean;
  sx?: SxProps<Theme>;
}>;

const Root = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "noContainer",
})<{ noContainer?: boolean }>(({ theme, noContainer }) => ({
  backgroundColor: noContainer ? undefined : theme.palette.grey[700],
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  padding: noContainer ? undefined : theme.spacing(0, 2),
}));

const ExpandIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<BoxProps & { expanded?: boolean }>(({ expanded }) => ({
  transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  transform: expanded ? "rotate(0)" : "rotate(180deg)",
  marginTop: "-2px",
}));

const AccordionSummary = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  flexDirection: "row",
  cursor: "pointer",
  userSelect: "none",
}));

const AccordionBody = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "expanded" && prop !== "hasChildren",
})<StackProps & { expanded?: boolean; hasChildren?: boolean }>(({ expanded, hasChildren, theme }) => ({
  overflowY: "auto",
  marginBottom: theme.spacing(expanded && hasChildren ? 2 : 0),
  transition: expanded
    ? "margin 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    : "margin 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms",
}));

const StickyContent = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const Buttons = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const Footer = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  ({ isOpen, toggleIsOpen, children, buttons, summary, footer, stickyContent, noContainer, sx }, ref) => {
    return (
      <Root
        sx={{
          ...sx,
          paddingBottom: isOpen || stickyContent || buttons ? 1 : 0,
        }}
        ref={ref}
        noContainer={noContainer}
      >
        <AccordionSummary onClick={toggleIsOpen} data-testid="accordion-summary">
          <Stack width="100%">{summary}</Stack>
          <ExpandIcon height="25px" width="25px" expanded={isOpen}>
            <CaretUpIcon />
          </ExpandIcon>
        </AccordionSummary>

        <AccordionBody expanded={isOpen} hasChildren={!!children}>
          {stickyContent && <StickyContent data-testid="accordion-sticky-content">{stickyContent}</StickyContent>}

          {children && (
            <Collapse in={isOpen}>
              <Stack spacing={2} data-testid="accordion-children">
                {children}
              </Stack>
            </Collapse>
          )}
        </AccordionBody>

        {buttons && (
          <Buttons spacing={2} data-testid="accordion-buttons">
            {buttons}
          </Buttons>
        )}

        {footer && (
          <Collapse in={isOpen}>
            <Footer spacing={2} data-testid="accordion-footer">
              {footer}
            </Footer>
          </Collapse>
        )}
      </Root>
    );
  },
);

export default Accordion;
