import { PropsWithChildren } from "react";
import { Stack, styled } from "@mui/material";

export type UnauthenticatedLayoutProps = PropsWithChildren<unknown>;

const Content = ({ children }: UnauthenticatedLayoutProps) => {
  return <>{children}</>;
};

const Root = styled(Stack)(({ theme }) => ({
  position: "relative",
  height: "100vh",
  backgroundColor: theme.palette.grey[900],
  backgroundImage: 'url("/connected-dots.svg")',
  backgroundPositionY: "-16.5vh",
  backgroundRepeat: "no-repeat",
}));

const UnauthenticatedLayout = ({ children }: UnauthenticatedLayoutProps) => {
  return <Root>{children}</Root>;
};

UnauthenticatedLayout.Content = Content;

export default UnauthenticatedLayout;
