interface IconProps {
  color?: string;
}

const UndoIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_82_2823)">
        <path
          d="M19 0C14.322 0.00333508 9.81054 1.73632 6.33333 4.86558V1.58333C6.33333 1.16341 6.16652 0.76068 5.86959 0.463748C5.57265 0.166815 5.16993 0 4.75 0C4.33007 0 3.92735 0.166815 3.63041 0.463748C3.33348 0.76068 3.16667 1.16341 3.16667 1.58333V6.33333C3.16667 7.59311 3.66711 8.80129 4.55791 9.69209C5.44871 10.5829 6.65689 11.0833 7.91667 11.0833H12.6667C13.0866 11.0833 13.4893 10.9165 13.7863 10.6196C14.0832 10.3227 14.25 9.91993 14.25 9.5C14.25 9.08007 14.0832 8.67735 13.7863 8.38041C13.4893 8.08348 13.0866 7.91667 12.6667 7.91667H7.91667C7.86327 7.90875 7.8104 7.89765 7.75833 7.88342C10.3384 5.28489 13.7404 3.66237 17.3835 3.2928C21.0267 2.92324 24.6852 3.82954 27.7345 5.857C30.7838 7.88447 33.035 10.9074 34.1037 14.4098C35.1724 17.9123 34.9925 21.677 33.5945 25.0615C32.1965 28.446 29.6673 31.2404 26.4384 32.9678C23.2096 34.6951 19.4813 35.2483 15.89 34.5328C12.2988 33.8174 9.0671 31.8778 6.74667 29.045C4.42625 26.2122 3.16092 22.6618 3.16667 19C3.16667 18.5801 2.99985 18.1773 2.70292 17.8804C2.40599 17.5835 2.00326 17.4167 1.58333 17.4167C1.16341 17.4167 0.76068 17.5835 0.463748 17.8804C0.166815 18.1773 0 18.5801 0 19C0 22.7578 1.11433 26.4313 3.20208 29.5558C5.28982 32.6804 8.25722 35.1156 11.729 36.5537C15.2008 37.9918 19.0211 38.368 22.7067 37.6349C26.3924 36.9018 29.7778 35.0922 32.435 32.435C35.0922 29.7778 36.9018 26.3924 37.6349 22.7067C38.368 19.0211 37.9918 15.2008 36.5537 11.729C35.1156 8.25722 32.6804 5.28982 29.5558 3.20208C26.4313 1.11433 22.7578 0 19 0V0Z"
          fill={color}
        />
        <path
          d="M19.0001 9.5C18.5802 9.5 18.1774 9.66682 17.8805 9.96375C17.5836 10.2607 17.4167 10.6634 17.4167 11.0833V19C17.4168 19.4199 17.5837 19.8226 17.8807 20.1194L22.6307 24.8694C22.9293 25.1578 23.3292 25.3174 23.7444 25.3138C24.1595 25.3102 24.5566 25.1437 24.8502 24.8501C25.1438 24.5566 25.3103 24.1594 25.3139 23.7443C25.3175 23.3292 25.1579 22.9292 24.8695 22.6306L20.5834 18.3445V11.0833C20.5834 10.6634 20.4166 10.2607 20.1197 9.96375C19.8227 9.66682 19.42 9.5 19.0001 9.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_82_2823">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UndoIcon;
