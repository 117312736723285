import { AppBar, Stack, Box, styled, useTheme } from "@mui/material";
import { useState } from "react";
import { ReadyState } from "../../../context/VehicleContext";
import LunchIcon from "../../../icons/LunchIcon";
import Avatar from "../../Avatars/Avatar";
import LockScreenDialog from "../../Dialogs/LockScreenDialog";
import Text from "../../Text";

const Root = styled(AppBar)(() => ({
  height: "94px",
  position: "relative",
}));

const BackgroundImage = styled(Box)(() => ({
  backgroundImage: 'url("/connected-dots.svg")',
  backgroundPositionY: "37%",
  backgroundPositionX: "58px",
  backgroundSize: "500px",
  backgroundRepeat: "no-repeat",
  transform: "scaleY(-1)",
  height: "100%",
  width: "100%",
  position: "absolute",
  opacity: "80%",
}));

interface ReadyStatusProps {
  readyStatus?: ReadyState;
}

const UserAvatar = ({ readyStatus }: ReadyStatusProps) => {
  const [lockScreenIsOpen, setLockScreenIsOpen] = useState(false);
  return (
    <>
      <Avatar
        size="34px"
        borderColor={({ palette }) =>
          readyStatus === ReadyState.LOGGED_IN ? palette.secondary.main : palette.grey[500]
        }
        filter={readyStatus === ReadyState.LOGGED_IN ? undefined : "grayscale(100%)"}
        // secret feature for demo
        onClick={() => setLockScreenIsOpen(true)}
      />
      <LockScreenDialog isOpen={lockScreenIsOpen} onClose={() => setLockScreenIsOpen(false)} />
    </>
  );
};

interface MealStatusProps {
  isOnMealBreak?: boolean;
  endMealBreak?: () => void;
}

const MealBreakStatus = ({ isOnMealBreak, endMealBreak }: MealStatusProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" spacing={1} onClick={endMealBreak}>
      {isOnMealBreak && (
        <Text variant="body2" fontStyle="Regular" color={theme.palette.secondary.main}>
          On Meal Break
        </Text>
      )}
      <Box width="24px" height="24px">
        <LunchIcon color={isOnMealBreak ? theme.palette.secondary.main : theme.palette.grey[600]} />
      </Box>
    </Stack>
  );
};

const Container = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  padding: theme.spacing(2),
  width: "100%",
}));

export type AppHeaderProps = ReadyStatusProps &
  MealStatusProps & {
    showMealBreakStatus?: boolean;
  };

const AppHeader = ({ showMealBreakStatus, readyStatus, isOnMealBreak, endMealBreak }: AppHeaderProps) => {
  return (
    <Root>
      <BackgroundImage />
      <Container direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <UserAvatar readyStatus={readyStatus} />
        </Stack>
        {showMealBreakStatus && <MealBreakStatus isOnMealBreak={isOnMealBreak} endMealBreak={endMealBreak} />}
      </Container>
    </Root>
  );
};

export default AppHeader;
