interface IconProps {
  color?: string;
}

const ArrowRightIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_82_3374)">
        <path
          d="M23.12 9.91L19.25 6.00001C19.157 5.90628 19.0464 5.83188 18.9246 5.78112C18.8027 5.73035 18.672 5.70421 18.54 5.70421C18.408 5.70421 18.2773 5.73035 18.1554 5.78112C18.0336 5.83188 17.923 5.90628 17.83 6.00001V6.00001C17.6437 6.18737 17.5392 6.44082 17.5392 6.70501C17.5392 6.96919 17.6437 7.22264 17.83 7.41001L21.39 11H1C0.734783 11 0.480429 11.1054 0.292893 11.2929C0.105357 11.4804 0 11.7348 0 12H0C0 12.2652 0.105357 12.5196 0.292893 12.7071C0.480429 12.8946 0.734783 13 1 13H21.45L17.83 16.61C17.7363 16.703 17.6619 16.8136 17.6111 16.9354C17.5603 17.0573 17.5342 17.188 17.5342 17.32C17.5342 17.452 17.5603 17.5827 17.6111 17.7046C17.6619 17.8264 17.7363 17.937 17.83 18.03C17.923 18.1237 18.0336 18.1981 18.1554 18.2489C18.2773 18.2997 18.408 18.3258 18.54 18.3258C18.672 18.3258 18.8027 18.2997 18.9246 18.2489C19.0464 18.1981 19.157 18.1237 19.25 18.03L23.12 14.15C23.6818 13.5875 23.9974 12.825 23.9974 12.03C23.9974 11.235 23.6818 10.4725 23.12 9.91V9.91Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_82_3374">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRightIcon;
