import { forwardRef, PropsWithChildren, ReactNode, useState } from "react";
import { Stack } from "@mui/material";
import Accordion from "../Accordion";
import Text from "../../Text";
import StandaloneBadge from "../../StandaloneBadge";

export type NotificationAccordionProps = PropsWithChildren<{
  notificationCount?: number;
  stickyNotification?: ReactNode;
}>;

const NotificationAccordion = forwardRef<HTMLDivElement, NotificationAccordionProps>(
  ({ children, stickyNotification, notificationCount }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Accordion
        ref={ref}
        isOpen={isOpen}
        toggleIsOpen={() => setIsOpen(!isOpen)}
        summary={
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Text variant="h5" fontStyle="SemiBold">
                Notifications
              </Text>
              <StandaloneBadge badgeContent={notificationCount} color="primary" />
            </Stack>

            <Text variant="body2" fontStyle="Regular">
              {isOpen ? "Hide" : "Show"} History
            </Text>
          </Stack>
        }
        stickyContent={stickyNotification}
        sx={{
          maxHeight: "542px",
        }}
      >
        {children}
      </Accordion>
    );
  },
);

export default NotificationAccordion;
