import { useEffect, useRef, useState } from "react";

export interface UseElementHeightProps {
  delayMs?: number;
}

const useElementHeight = <E extends HTMLElement>({ delayMs = 1000 }: UseElementHeightProps) => {
  const ref = useRef<E>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newHeight = ref.current?.offsetHeight ?? 0;
      if (newHeight !== height) setHeight(newHeight);
    }, delayMs);

    return () => clearInterval(interval);
  }, [height, delayMs]);

  return {
    ref,
    height,
  };
};

export default useElementHeight;
