import { PropsWithChildren } from "react";
import { Box, Select as MuiSelect, styled } from "@mui/material";
import { useField } from "formik";
import { getSelectOptionsFromChildren } from "./utils";

export type SelectProps = PropsWithChildren<{
  name: string;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  endAdornment?: React.ReactNode;
}>;

const Placeholder = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const Select = ({ name, placeholder, endAdornment, children, ...props }: SelectProps) => {
  const [field, meta] = useField(name);
  const id = `select-${name}`;

  const options = getSelectOptionsFromChildren(children);
  const selectedOption = options.find(({ value }) => value === field.value);

  return (
    <MuiSelect
      error={meta.touched && !!meta.error}
      variant="outlined"
      {...field}
      value={field.value ?? ""}
      {...props}
      name={name}
      id={id}
      renderValue={(selected) => (selected === "" ? <Placeholder>{placeholder}</Placeholder> : selectedOption?.label)}
      fullWidth
      displayEmpty
      endAdornment={endAdornment}
      IconComponent={endAdornment ? () => null : undefined}
    >
      {children}
    </MuiSelect>
  );
};

export default Select;
