import { Stack } from "@mui/material";
import { forwardRef, PropsWithChildren } from "react";

export type AppLayoutProps = PropsWithChildren<unknown>;

const Header = forwardRef<HTMLElement, AppLayoutProps>(({ children }, ref) => {
  return <Stack ref={ref}>{children}</Stack>;
});

const StatusBar = forwardRef<HTMLElement, AppLayoutProps>(({ children }, ref) => {
  return <Stack ref={ref}>{children}</Stack>;
});

const Footer = forwardRef<HTMLElement, AppLayoutProps>(({ children }, ref) => {
  return (
    <Stack ref={ref} position="sticky" bottom={0} width="100%" zIndex={2}>
      {children}
    </Stack>
  );
});

const Content = ({ children }: AppLayoutProps) => {
  return (
    <Stack padding={({ spacing }) => spacing(3, 2)} zIndex={1}>
      {children}
    </Stack>
  );
};

const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <Stack height="100vh" justifyContent="space-between">
      {children}
    </Stack>
  );
};

AppLayout.Header = Header;
AppLayout.StatusBar = StatusBar;
AppLayout.Footer = Footer;
AppLayout.Content = Content;

export default AppLayout;
