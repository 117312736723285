import { Incident } from "../types";

export const mockIncidents: Incident[] = [
  {
    id: "b5bb6e71-753d-4772-a890-9ae4a0d9dc04",
    incidentNo: "20012",
    title: "PRIO 1 - Incident ID: 20012 S",
    address: "District Court, 415 George Street,  Brisbane, QLD 4000",
    message: (
      <>
        <span>
          <strong>ProQA:</strong> 10C02 | <strong>Called:</strong> 9:27
          <br />
          <strong>Address:</strong> District Court, 415 George Street, Brisbane, QLD 4000
        </span>
        <br />
        <br />
        <span>
          <strong>Type:</strong> Chest pain, Cardiac history
          <br />
          <strong>Sec Type:</strong>
          <br />
          <strong>Patient:</strong> John Smith M 43year
        </span>
        <br />
        <br />
        <span>
          <strong>Caller:</strong> Jane 0404 643 851
          <br />
          <strong>Hospital:</strong> N/A
          <br />
          <strong>Notes:</strong> has taken asprin
          <br />
          <strong>Caution Notes:</strong>
        </span>
        <br />
        <br />
        <span>
          <strong>Type notes:</strong>
          <br />
          [9:27] Type Notes: chest pain
          <br />
          Note: 10C02 - 0 - ICP - AP - N You are responding to a patient with chest pain. The patient is a 43 year old
          male who....
        </span>
      </>
    ),
    statusTimes: (
      <>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "40%" }}></th>
              <th style={{ width: "60%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Call:</td>
              <td>09:27</td>
            </tr>
            <tr>
              <td>Dispatched:</td>
              <td>09:30</td>
            </tr>
            <tr>
              <td>Acknowledged:</td>
              <td>09:30</td>
            </tr>
            <tr>
              <td>Enroute:</td>
              <td>09:33</td>
            </tr>
            <tr>
              <td>Arrvied Scene:</td>
              <td>09:51</td>
            </tr>
            <tr>
              <td>Depart Scene:</td>
              <td>10:58</td>
            </tr>
            <tr>
              <td>Arrived PAH:</td>
              <td>11:15</td>
            </tr>
            <tr>
              <td>Triaged:</td>
              <td>11:21</td>
            </tr>
            <tr>
              <td>Ramped:</td>
              <td>11:40</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    id: "ma8dd3-88gd-dd88s-1kka-hg764g7fds4hj7",
    incidentNo: "20002",
    title: "PRIO 1 - Incident ID: 20002 S",
    address: "District Court, 415 George Street,  Brisbane, QLD 4000",
    message: (
      <>
        <span>
          <strong>ProQA:</strong> 10C02 | <strong>Called:</strong> 3:27
          <br />
          <strong>Address:</strong> District Court, 415 George Street, Brisbane, QLD 4000
        </span>
        <br />
        <br />
        <span>
          <strong>Type:</strong> Chest pain, Cardiac history
          <br />
          <strong>Sec Type:</strong>
          <br />
          <strong>Patient:</strong> John Smith M 43year
        </span>
        <br />
        <br />
        <span>
          <strong>Caller:</strong> Jane 0404 643 851
          <br />
          <strong>Hospital:</strong> N/A
          <br />
          <strong>Notes:</strong> has taken asprin
          <br />
          <strong>Caution Notes:</strong>
        </span>
        <br />
        <br />
        <span>
          <strong>Type notes:</strong>
          <br />
          [9:27] Type Notes: chest pain
          <br />
          Note: 10C02 - 0 - ICP - AP - N You are responding to a patient with chest pain. The patient is a 43 year old
          male who....
        </span>
      </>
    ),
    statusTimes: (
      <>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "40%" }}></th>
              <th style={{ width: "60%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Call:</td>
              <td>03:27</td>
            </tr>
            <tr>
              <td>Dispatched:</td>
              <td>03:30</td>
            </tr>
            <tr>
              <td>Acknowledged:</td>
              <td>03:30</td>
            </tr>
            <tr>
              <td>Enroute:</td>
              <td>03:33</td>
            </tr>
            <tr>
              <td>Arrvied Scene:</td>
              <td>03:51</td>
            </tr>
            <tr>
              <td>Depart Scene:</td>
              <td>04:58</td>
            </tr>
            <tr>
              <td>Arrived PAH:</td>
              <td>05:15</td>
            </tr>
            <tr>
              <td>Triaged:</td>
              <td>05:21</td>
            </tr>
            <tr>
              <td>Ramped:</td>
              <td>05:40:</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
];
