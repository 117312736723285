import { Box, Divider, IconButton, Stack, styled, useTheme } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import CloseBoldIcon from "../../../icons/CloseBoldIcon";
import SearchIcon from "../../../icons/SearchIcon";
import { generalStatuses, preCannedMessages } from "./constants";
// components
import Dialog from "../Dialog";
import StatusUpdateAccordion from "../../Accordions/StatusUpdateAccordion";
import StatusButton from "../../Buttons/StatusButton";
import TextField from "../../FormInputs/TextField";
import Text from "../../Text";

interface DialogHeaderProps {
  onClose?: () => void;
}

const DialogHeaderRoot = styled(Stack)(({ theme }) => ({
  alignItems: "flex-end",
  backgroundColor: theme.palette.grey[900],
  margin: theme.spacing(-2, -2, 2),
  height: "94px",
  padding: theme.spacing(2),
}));

const DialogHeader = ({ onClose }: DialogHeaderProps) => {
  const theme = useTheme();

  return (
    <DialogHeaderRoot direction="row">
      <Stack spacing={2} direction="row" alignItems="center">
        <IconButton aria-label="close" onClick={onClose} sx={{ height: "32px", width: "32px", padding: 1 }}>
          <CloseBoldIcon color={theme.palette.common.white} />
        </IconButton>
        <Text variant="h5" fontStyle="SemiBold">
          General Status Update
        </Text>
      </Stack>
    </DialogHeaderRoot>
  );
};

export interface GeneralStatusUpdateDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}

const GeneralStatusUpdateDialog = ({ isOpen, onClose }: GeneralStatusUpdateDialogProps) => {
  const theme = useTheme();
  const formik = useFormik<{ text: string }>({
    initialValues: {
      text: "",
    },
    onSubmit: () => {
      // no submit logic required
    },
  });

  const textFilter = (value: string) => value.toLowerCase().includes(formik.values.text.toLowerCase());

  return (
    <Dialog header={<DialogHeader onClose={onClose} />} isOpen={isOpen} onClose={onClose} fullscreen>
      <FormikProvider value={formik}>
        <Stack spacing={1}>
          <TextField
            name="text"
            placeholder="Start typing"
            startAdornment={
              <Box height="24px" width="24px" marginRight={1}>
                <SearchIcon color={theme.palette.common.white} />
              </Box>
            }
          />

          <Stack spacing={2}>
            <StatusUpdateAccordion
              renderSummary={() => (
                <Text variant="h5" color={theme.palette.grey[400]} fontStyle="SemiBold">
                  General status
                </Text>
              )}
              noContainer
            >
              {generalStatuses.filter(textFilter).map((value) => (
                <StatusButton key={value}>{value}</StatusButton>
              ))}
            </StatusUpdateAccordion>

            <Divider />

            <StatusUpdateAccordion
              renderSummary={() => (
                <Text variant="h5" color={theme.palette.grey[400]} fontStyle="SemiBold">
                  Pre-canned message
                </Text>
              )}
              noContainer
            >
              {preCannedMessages.filter(textFilter).map((value) => (
                <StatusButton key={value}>{value}</StatusButton>
              ))}
            </StatusUpdateAccordion>
          </Stack>
        </Stack>
      </FormikProvider>
    </Dialog>
  );
};

export default GeneralStatusUpdateDialog;
