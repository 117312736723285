import { Divider, Stack } from "@mui/material";
import { getFieldName, PreShiftChecklistField, VehicleLoginFormField } from "./schema";
// components
import CheckboxGroup, { CheckboxOption } from "../../FormInputs/CheckboxGroup";
import StepTitle from "./StepTitle";
import TextField from "../../FormInputs/TextField";
import CheckboxStepLabel from "./CheckboxStepLabel";

export interface PreShiftChecklistProps {
  goBack: () => void;
}

const binaryOptions: CheckboxOption[] = [
  {
    id: true,
    label: "Yes",
  },
  {
    id: false,
    label: "No",
  },
];

const PreShiftChecklist = ({ goBack }: PreShiftChecklistProps) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <StepTitle goBack={goBack}>Step 4: Pre-shift checklist</StepTitle>
        <Divider />
      </Stack>

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={1} label="Who is this Pre-shift checklist for?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.WhoIsItFor,
            nestedField: "selection",
          })}
          options={[
            {
              id: "myself",
              label: "Myself",
            },
            {
              id: "everyone",
              label: "All assigned crew",
            },
          ]}
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.WhoIsItFor,
            nestedField: "comments",
          })}
          placeholder="Leave a comment"
        />
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={2} label="Are you Covid safe?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.CovidSafe,
            nestedField: "selection",
          })}
          options={binaryOptions}
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.CovidSafe,
            nestedField: "comments",
          })}
          placeholder="Leave a comment"
        />
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={3} label="Are you drug and alcohol free?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.DrugAndAlcoholFree,
            nestedField: "selection",
          })}
          options={binaryOptions}
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.DrugAndAlcoholFree,
            nestedField: "comments",
          })}
          placeholder="Leave a comment"
        />
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <CheckboxGroup
          label={<CheckboxStepLabel step={4} label="Are you fatigued?" />}
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.Fatigued,
            nestedField: "selection",
          })}
          options={binaryOptions}
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.PreShiftChecklist,
            field: PreShiftChecklistField.Fatigued,
            nestedField: "comments",
          })}
          placeholder="Leave a comment"
        />
      </Stack>

      <Divider />
    </Stack>
  );
};

export default PreShiftChecklist;
