import { IconButton, Stack, styled, useTheme, Box } from "@mui/material";
import CloseIcon from "../../../icons/CloseIcon";
// components
import Dialog from "../Dialog";
import Button from "../../Buttons/Button";
import Webcam from "react-webcam";
import { Container } from "@mui/system";
import TakePhotoIcon from "../../../icons/TakePhotoIcon";
import { useRef } from "react";

export interface TakePhotoDialogProps {
  isOpen?: boolean;
  onClose?(photoTaken?: string): void;
}

const TakePhotoDialogBase = ({ isOpen, onClose }: TakePhotoDialogProps) => {
  const theme = useTheme();

  const cameraRef = useRef<Webcam>(null);

  const CamContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    borderRadius: theme.shape.borderRadius,
    margin: `${theme.spacing(2)} 0`,
  }));

  const takePhoto = () => {
    if (onClose) {
      if (cameraRef.current) {
        const photo = cameraRef.current.getScreenshot();
        if (photo) {
          onClose(photo);
          return;
        } else {
          //camera unavailable or error
        }
      } else {
        //component didn't render
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        if (onClose) onClose();
      }}
      header={
        <Stack flexDirection="row" justifyContent="flex-end">
          <span>
            <IconButton
              aria-label="close"
              onClick={() => {
                if (onClose) onClose();
              }}
              sx={{ height: "32px", width: "32px", padding: 1 }}
            >
              <CloseIcon color={theme.palette.common.white} />
            </IconButton>
          </span>
        </Stack>
      }
    >
      <CamContainer disableGutters={true}>
        <Webcam ref={cameraRef} height="100%" imageSmoothing={true} screenshotQuality={1} />
      </CamContainer>
      <Button
        fullWidth
        startIcon={
          <Box width="24px" height="24px">
            <TakePhotoIcon color={theme.palette.common.white} />
          </Box>
        }
        onClick={takePhoto}
      >
        Take photo
      </Button>
    </Dialog>
  );
};

const TakePhotoDialog = (props: TakePhotoDialogProps) => {
  const { isOpen } = props;
  return isOpen ? <TakePhotoDialogBase {...props} /> : null;
};

export default TakePhotoDialog;
