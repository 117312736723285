import { Stack, styled } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import Button from "../Buttons/Button";
import NotificationCard from "../NotificationCard";
import Text from "../Text";

export type IncidentBoxProps = PropsWithChildren<{
  title: string;
  message: string | ReactNode;
  viewAndRespond?: () => void;
}>;

const Root = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2, 2, 3, 2),
}));

const IncidentBox = ({ title, message, viewAndRespond, children }: IncidentBoxProps) => {
  return (
    <Root spacing={2}>
      <Text variant="h5" fontStyle="SemiBold" data-testid="incident-box-title">
        {title}
      </Text>
      <NotificationCard
        alertLevel="critical"
        title="Dispatcher"
        dateTime={new Date("Nov 28, 2022, 09:20:00")}
        message={message}
        icon="dispatch"
      >
        {children}
      </NotificationCard>
      <Button color="pink" fullWidth onClick={viewAndRespond}>
        View & Respond
      </Button>
    </Root>
  );
};

export default IncidentBox;
