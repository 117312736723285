import { PropsWithChildren, useState } from "react";
import { Stack, Box, styled } from "@mui/material";
import CaretUpIcon from "../../../icons/CaretUpIcon";
import Text from "../../Text";
import Accordion from "../Accordion";

export interface HideButtonProps {
  hide: () => void;
}

const HideButtonRoot = styled(Stack)(() => ({
  alignItems: "center",
  overflow: "hidden",
  cursor: "pointer",
}));

const HideButton = ({ hide }: HideButtonProps) => {
  return (
    <HideButtonRoot onClick={hide} role="button">
      <Box height="25px" width="25px">
        <CaretUpIcon />
      </Box>
    </HideButtonRoot>
  );
};

export type DetailsAccordionProps = PropsWithChildren<unknown>;

const DetailsAccordion = ({ children }: DetailsAccordionProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const hide = () => setIsOpen(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  return (
    <Accordion
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
      summary={
        <Text variant="h5" fontStyle="SemiBold">
          Details
        </Text>
      }
      footer={<HideButton hide={hide} />}
    >
      <Text fontStyle="Regular" variant="body2" color={({ palette }) => palette.common.white}>
        <span>{children}</span>
      </Text>
    </Accordion>
  );
};

export default DetailsAccordion;
