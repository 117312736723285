import { Children, PropsWithChildren } from "react";
import { Stack, Box, styled } from "@mui/material";

const Labels = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(-2),
  marginLeft: theme.spacing(-2),
}));

const Label = styled(Box)(({ theme }) => ({
  display: "inline-block",
  margin: theme.spacing(0, 0, 2, 2),
}));

export type StatusUpdateProps = PropsWithChildren<unknown>;

const StatusUpdate = ({ children }: StatusUpdateProps) => {
  return (
    <Stack overflow="hidden">
      <Labels>
        {Children.map(children, (child) => (
          <Label>{child}</Label>
        ))}
      </Labels>
    </Stack>
  );
};

export default StatusUpdate;
