import { PropsWithChildren, ReactNode } from "react";
import { Box, Dialog as MuiDialog, Stack, StackProps, styled } from "@mui/material";

export type DialogProps = PropsWithChildren<{
  isOpen?: boolean;
  onClose?: () => void;
  header?: ReactNode;
  fullscreen?: boolean;
}>;

const Root = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "fullscreen" && prop !== "noHeader",
})<StackProps & { fullscreen?: boolean; noHeader?: boolean }>(({ theme, fullscreen, noHeader }) => ({
  borderRadius: "4px",
  border: fullscreen ? undefined : `1px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(noHeader ? 3 : 2, fullscreen ? 2 : 3, 3),
}));

const Dialog = ({ isOpen, onClose, children, header, fullscreen }: DialogProps) => {
  return isOpen ? (
    <MuiDialog
      open
      onClose={onClose}
      fullScreen={fullscreen}
      PaperProps={{
        sx: {
          width: "100%",
          backgroundColor: ({ palette }) => palette.background.default,
          backgroundImage: "none",
        },
      }}
    >
      <Root fullscreen={fullscreen} noHeader={!header}>
        <Box data-testid="dialog-header">{header}</Box>
        <Box data-testid="dialog-children">{children}</Box>
      </Root>
    </MuiDialog>
  ) : null;
};

export default Dialog;
