import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import App from "./App";
import darkTheme from "./themes/darkTheme";

// context
import { UserProvider } from "./context/UserContext";
import { AuthProvider } from "./context/AuthContext";
import { VehicleProvider } from "./context/VehicleContext";
import { IncidentProvider } from "./context/IncidentContext";
import { HeaderRefProvider } from "./context/HeaderRefContext";
import { StatusBarRefProvider } from "./context/StatusBarRefContext";
import { FooterRefProvider } from "./context/FooterRefContext";

const Root = () => {
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <HeaderRefProvider>
          <StatusBarRefProvider>
            <FooterRefProvider>
              <AuthProvider>
                <UserProvider>
                  <VehicleProvider>
                    <IncidentProvider>
                      <App />
                    </IncidentProvider>
                  </VehicleProvider>
                </UserProvider>
              </AuthProvider>
            </FooterRefProvider>
          </StatusBarRefProvider>
        </HeaderRefProvider>
      </ThemeProvider>
    </Router>
  );
};

export default Root;
