import { PropsWithChildren } from "react";
import { Property } from "csstype";
import { Theme, Typography, TypographyProps, styled } from "@mui/material";
import fontStyles, { FontStyle } from "../../themes/fontStyles";

export type TextProps = PropsWithChildren<
  {
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "body1" | "body2" | "button" | "caption" | "overline";
    fontStyle?: FontStyle;
    color?: string | ((theme: Theme) => string);
    noWrap?: boolean;
    underlined?: boolean;
    cursor?: Property.Cursor;
  } & Pick<TypographyProps, "textAlign" | "textTransform" | "onClick">
>;

const Root = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "underlined" && prop !== "cursor",
})<TypographyProps & { underlined?: boolean; cursor?: Property.Cursor }>(({ underlined, cursor }) => ({
  textDecoration: underlined ? "underline" : undefined,
  cursor,
}));

const Text = ({ children, fontStyle = "Regular", variant = "body1", color, noWrap, cursor, ...props }: TextProps) => {
  return (
    <Root
      variant={variant}
      fontWeight={fontStyles[fontStyle]}
      color={color}
      whiteSpace={noWrap ? "nowrap" : undefined}
      cursor={cursor}
      {...props}
    >
      {children}
    </Root>
  );
};

export default Text;
