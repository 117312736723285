interface IconProps {
  color?: string;
}

const HomeIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_82_2825)">
        <path
          d="M36.6083 14.3593L24.5987 2.34809C23.1123 0.866068 21.099 0.033844 19 0.033844C16.9011 0.033844 14.8877 0.866068 13.4014 2.34809L1.39177 14.3593C0.949109 14.7991 0.598155 15.3224 0.35925 15.8989C0.120345 16.4753 -0.00175813 17.0935 1.91258e-05 17.7175V33.2611C1.91258e-05 34.5209 0.500464 35.729 1.39126 36.6198C2.28206 37.5106 3.49024 38.0111 4.75002 38.0111H33.25C34.5098 38.0111 35.718 37.5106 36.6088 36.6198C37.4996 35.729 38 34.5209 38 33.2611V17.7175C38.0018 17.0935 37.8797 16.4753 37.6408 15.8989C37.4019 15.3224 37.0509 14.7991 36.6083 14.3593ZM23.75 34.8444H14.25V28.6156C14.25 27.3558 14.7505 26.1476 15.6413 25.2568C16.5321 24.366 17.7402 23.8656 19 23.8656C20.2598 23.8656 21.468 24.366 22.3588 25.2568C23.2496 26.1476 23.75 27.3558 23.75 28.6156V34.8444ZM34.8333 33.2611C34.8333 33.681 34.6665 34.0837 34.3696 34.3807C34.0727 34.6776 33.6699 34.8444 33.25 34.8444H26.9167V28.6156C26.9167 26.516 26.0826 24.5023 24.5979 23.0177C23.1133 21.533 21.0996 20.6989 19 20.6989C16.9004 20.6989 14.8868 21.533 13.4021 23.0177C11.9174 24.5023 11.0834 26.516 11.0834 28.6156V34.8444H4.75002C4.33009 34.8444 3.92737 34.6776 3.63043 34.3807C3.3335 34.0837 3.16669 33.681 3.16669 33.2611V17.7175C3.16815 17.2979 3.33482 16.8957 3.6306 16.5981L15.6402 4.59167C16.5327 3.70331 17.7407 3.20458 19 3.20458C20.2593 3.20458 21.4673 3.70331 22.3598 4.59167L34.3694 16.6028C34.6641 16.8993 34.8306 17.2995 34.8333 17.7175V33.2611Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_82_2825">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeIcon;
