import { PropsWithChildren, createContext, useContext, Dispatch, useReducer } from "react";
import produce from "immer";

export enum Status {
  OffDuty = "Off Duty",
  Activated = "Activated",
  Acknowledged = "Acknowledged",
  Mobile = "Mobile",
  Delayed = "Delayed",
  AtDestination = "At Destn",
  Triaged = "Triaged",
  Ramped = "Ramped",
  OffStretcher = "Off Stretcher",
  OffloadedButCleaning = "Offloaded but cleaning",
  CompletingPaperwork = "Completing paperwork",
  DelayedInfectiousClean = "Delayed infectious clean",
  ReStocking = "Restocking",
  Available = "Available",
  OnMealBreak = "Meal Break",
  OnScene = "On Scene",
  AvailableOnScene = "Available On Scene",
  DepartScene = "Depart Scene",
  DepartScenePAH = "Depart Scene - PAH",
  OutLocal = "Out Local",
}

const statusMatrix: Record<Status, Status[]> = {
  [Status.OffDuty]: [Status.Available],
  [Status.OnMealBreak]: [Status.Available],
  [Status.Activated]: [Status.Acknowledged, Status.Delayed],
  [Status.Acknowledged]: [Status.Mobile, Status.Delayed],
  [Status.Mobile]: [Status.OnScene, Status.Delayed],
  [Status.OnScene]: [Status.DepartScene, Status.AvailableOnScene, Status.Delayed],
  [Status.AvailableOnScene]: [Status.OnScene, Status.Activated, Status.ReStocking, Status.OutLocal, Status.Delayed],
  [Status.DepartScene]: [Status.Triaged, Status.Delayed, Status.OnScene],
  [Status.DepartScenePAH]: [Status.Triaged, Status.Delayed, Status.AtDestination],
  [Status.AtDestination]: [Status.Triaged, Status.Delayed],
  [Status.Triaged]: [Status.Ramped, Status.OffStretcher, Status.Delayed],
  [Status.Ramped]: [Status.OffStretcher, Status.Delayed],
  [Status.OffStretcher]: [
    Status.Available,
    Status.OffloadedButCleaning,
    Status.CompletingPaperwork,
    Status.DelayedInfectiousClean,
    Status.ReStocking,
  ],
  [Status.OffloadedButCleaning]: [
    Status.Available,
    Status.CompletingPaperwork,
    Status.DelayedInfectiousClean,
    Status.ReStocking,
  ],
  [Status.CompletingPaperwork]: [
    Status.Available,
    Status.OffloadedButCleaning,
    Status.DelayedInfectiousClean,
    Status.ReStocking,
  ],
  [Status.DelayedInfectiousClean]: [
    Status.Available,
    Status.OffloadedButCleaning,
    Status.CompletingPaperwork,
    Status.ReStocking,
  ],
  [Status.ReStocking]: [
    Status.Available,
    Status.OffloadedButCleaning,
    Status.CompletingPaperwork,
    Status.DelayedInfectiousClean,
  ],
  [Status.Delayed]: [],
  [Status.OutLocal]: [Status.Available],
  [Status.Available]: [Status.Activated, Status.ReStocking, Status.OutLocal, Status.Delayed, Status.OnMealBreak],
};

export const statusWarningMessages: Partial<Record<Status, string>> = {
  [Status.AtDestination]: "Have you been triaged?",
  [Status.Triaged]: "Are you ramped?",
  [Status.Ramped]: "Are you off stretcher?",
  [Status.OnMealBreak]: "Meal break concluded",
};

/**
 * State
 */

export interface UserState {
  previousStatus: Status;
  status: Status;
  allowedStatuses: Status[];
}

const initialState: UserState = {
  previousStatus: Status.OffDuty,
  status: Status.OffDuty,
  allowedStatuses: statusMatrix[Status.OffDuty],
};

/**
 * Reducer
 */

type UserAction = { type: "SET_STATUS"; status: Status };

const reducer = (state: UserState, action: UserAction): UserState => {
  const type = action.type;
  switch (type) {
    case "SET_STATUS":
      return produce(state, (draft) => {
        draft.previousStatus = state.status;
        draft.status = action.status;

        switch (action.status) {
          case Status.Delayed:
            draft.allowedStatuses = [...statusMatrix[action.status], state.status];
            break;

          default:
            draft.allowedStatuses = [...statusMatrix[action.status]];
            break;
        }
      });

    default:
      console.warn(`UserAction ${type} not supported`);
      return state;
  }
};

const userActions = {
  setStatus: (dispatch: Dispatch<UserAction>, status: Status) => {
    dispatch({ type: "SET_STATUS", status });
  },
};

/**
 * Context
 */

const UserStateContext = createContext<UserState>(undefined!);
const UserDispatchContext = createContext<Dispatch<UserAction>>(undefined!);

export const UserProvider = ({ children, ...defaultState }: PropsWithChildren<Partial<UserState>>) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...defaultState });
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = (): UserState => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be within UserProvider");
  }
  return context;
};

const useUserDispatch = (): Dispatch<UserAction> => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be within UserProvider");
  }
  return context;
};

export { useUserState, useUserDispatch, userActions };
