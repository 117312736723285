import { createContext, PropsWithChildren, RefObject, useContext, useRef } from "react";

const StatusBarRefContext = createContext<RefObject<HTMLElement>>(undefined!);

const StatusBarRefProvider = ({ children }: PropsWithChildren<unknown>) => {
  const ref = useRef<HTMLElement>(null);
  return <StatusBarRefContext.Provider value={ref}>{children}</StatusBarRefContext.Provider>;
};

const useStatusBarRef = () => {
  const context = useContext(StatusBarRefContext);
  if (context === undefined) {
    throw new Error("useStatusBarRef must be within StatusBarRefProvider");
  }
  return context;
};

export { StatusBarRefProvider, useStatusBarRef };
