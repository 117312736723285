import { CrewMember } from "../types";

export const mockCrewMembers: CrewMember[] = [
  { id: "mike-smith", name: "Mike Smith" },
  { id: "julie-williams", name: "Julie Williams" },
  { id: "mary-jones", name: "Mary Jones" },
  { id: "harry-ford", name: "Harry Ford" },
  { id: "marley-smith", name: "Marley Smith" },
  { id: "nate-matthews", name: "Nate Matthews" },
  { id: "mark-oswald", name: "Mark Oswald" },
  { id: "stella-wilkie", name: "Stella Wilkie" },
];
