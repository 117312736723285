import { Stack, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Status, userActions, useUserDispatch } from "../../context/UserContext";
import { ReadyState, useVehicleState } from "../../context/VehicleContext";
import Text from "../Text";

const Root = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(0, 0, 0.5, 0.5),
  overflow: "hidden",
}));

const Info = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  padding: theme.spacing(1, 2),
}));

const Warning = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  padding: theme.spacing(0.5, 2),
}));

interface TimerProps {
  initialTime?: { minutes: number; seconds: number };
  overtime?: { minutes: number; seconds: number };
  overtimeCallback?: (overtime: boolean) => void;
  resetOnChange?: any;
  switchStatus?: { minutes: number; seconds: number };
  switchStatusCallback?: (overtime: boolean) => void;
}

const Timer = ({
  initialTime,
  overtime,
  overtimeCallback,
  resetOnChange,
  switchStatus,
  switchStatusCallback,
}: TimerProps) => {
  const [minutes, setMinutes] = useState(initialTime?.minutes ?? 0);
  const [seconds, setSeconds] = useState(initialTime?.seconds ?? 0);

  const isOvertime = overtime ? minutes >= overtime.minutes && seconds >= overtime.seconds : false;
  const toSwitchStatus = switchStatus ? minutes >= switchStatus.minutes && seconds >= switchStatus.seconds : false;

  useEffect(() => {
    //Reset the timer to it's inital state
    if (!isNaN(Number(initialTime?.minutes))) setMinutes(Number(initialTime?.minutes));
    if (!isNaN(Number(initialTime?.seconds))) setSeconds(Number(initialTime?.seconds));
  }, [initialTime?.minutes, initialTime?.seconds, resetOnChange]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds < 59) {
        setSeconds(seconds + 1);
      } else if (seconds >= 59) {
        setMinutes(minutes + 1);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (isOvertime) {
      overtimeCallback && overtimeCallback(true);
    } else {
      overtimeCallback && overtimeCallback(false);
    }
  }, [isOvertime, overtimeCallback]);

  useEffect(() => {
    if (toSwitchStatus) {
      switchStatusCallback && switchStatusCallback(true);
    } else {
      switchStatusCallback && switchStatusCallback(false);
    }
  }, [toSwitchStatus, switchStatusCallback]);

  return minutes === 0 && seconds === 0 ? null : (
    <Text
      variant="body1"
      fontStyle="SemiBold"
      color={({ palette }) => (isOvertime ? palette.error.main : palette.info.contrastText)}
    >
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </Text>
  );
};

export interface StatusBarProps {
  status: Status;
  onClickStatus?: () => void;
  initialTime?: { minutes: number; seconds: number };
  overtime?: { minutes: number; seconds: number };
  switchStatus?: { minutes: number; seconds: number };
  warningMessage?: string;
}

const StatusBar = ({ status, onClickStatus, initialTime, overtime, warningMessage, switchStatus }: StatusBarProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const { readyState } = useVehicleState();
  const userDispatch = useUserDispatch();
  useEffect(() => {
    setShowWarning(false);
  }, [status]);

  return (
    <Root>
      <Info direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={0.5}>
          <Text
            variant="body1"
            color={({ palette }) => palette.info.contrastText}
            textTransform="uppercase"
            fontStyle="SemiBold"
          >
            Status:
          </Text>
          <Text
            variant="body1"
            color={({ palette }) => palette.info.contrastText}
            textTransform="uppercase"
            fontStyle="SemiBold"
            underlined={!!onClickStatus && readyState === ReadyState.LOGGED_IN}
            cursor={!!onClickStatus ? "pointer" : "default"}
            onClick={onClickStatus}
          >
            {status}
          </Text>
        </Stack>

        {initialTime && (
          <Timer
            initialTime={initialTime}
            overtime={overtime}
            overtimeCallback={(isOvertime: boolean) => {
              if (isOvertime) {
                setShowWarning(true);
              }
            }}
            resetOnChange={warningMessage}
            switchStatus={switchStatus}
            switchStatusCallback={(toSwitch: boolean) => {
              if (toSwitch) {
                userActions.setStatus(userDispatch, Status.Available);
              }
            }}
          />
        )}
      </Info>
      {warningMessage && showWarning && (
        <Warning>
          <Text
            variant="body2"
            color={({ palette }) => palette.warning.contrastText}
            textTransform="uppercase"
            fontStyle="SemiBold"
          >
            {warningMessage}
          </Text>
        </Warning>
      )}
    </Root>
  );
};

export default StatusBar;
