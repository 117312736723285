interface IconProps {
  color?: string;
}

const ArrowFilledUpIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 28 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.47843 17.5H20.5218C20.7525 17.499 20.9778 17.4297 21.1691 17.3007C21.3604 17.1717 21.5092 16.9889 21.5966 16.7753C21.684 16.5618 21.7061 16.3271 21.6602 16.101C21.6142 15.8749 21.5022 15.6675 21.3384 15.505L14.8284 8.995C14.72 8.88565 14.5909 8.79886 14.4488 8.73963C14.3066 8.6804 14.1541 8.64991 14.0001 8.64991C13.8461 8.64991 13.6936 8.6804 13.5514 8.73963C13.4092 8.79886 13.2802 8.88565 13.1718 8.995L6.66176 15.505C6.49793 15.6675 6.38597 15.8749 6.34002 16.101C6.29407 16.3271 6.3162 16.5618 6.40361 16.7753C6.49103 16.9889 6.6398 17.1717 6.83111 17.3007C7.02242 17.4297 7.24769 17.499 7.47843 17.5Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowFilledUpIcon;
