import { ReactNode } from "react";
import { useField } from "formik";
import { MenuItem } from "@mui/material";
// components
import Select from "../../FormInputs/Select";
import Text from "../../Text";
import ChangeButton from "../../Buttons/ChangeButton";

export enum TypeOfCrewsPlaceholders {
  Observer = "Observer",
  Crew = "Crew",
}

type CrewMemberSelectProps = {
  fieldName: string;
  index: number;
  readOnly?: boolean;
  changeOnly?: boolean;
  typeOfCrew?: TypeOfCrewsPlaceholders;
  children: ReactNode[];
};

const CrewMemberSelect = ({ fieldName, index, readOnly, changeOnly, typeOfCrew, children }: CrewMemberSelectProps) => {
  const [field, _, helpers] = useField<string[]>(fieldName);

  const clearField = () => {
    helpers.setValue(field.value.filter((_, idx) => idx !== index));
  };

  // show clear field option on the last select if there are no options left to choose
  const showClearFieldOption = children.length === field.value.length && children.length === index + 1;

  const crewType = typeOfCrew || TypeOfCrewsPlaceholders.Crew;

  return (
    <Select
      name={fieldName + `[${index}]`}
      placeholder={`${crewType} ${index + 1} full name`}
      readOnly={readOnly || changeOnly}
      endAdornment={changeOnly ? <ChangeButton /> : undefined}
    >
      <MenuItem sx={{ display: showClearFieldOption ? undefined : "none" }} onClick={clearField}>
        <Text variant="caption">Clear input</Text>
      </MenuItem>
      {children}
    </Select>
  );
};

export default CrewMemberSelect;
