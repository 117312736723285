import { BaseTextFieldProps, TextField as MuiTextField } from "@mui/material";
import { useField } from "formik";

export interface TextFieldProps {
  name: string;
  fullWidth?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  type?: BaseTextFieldProps["type"];
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
}

const TextField = ({ name, endAdornment, startAdornment, ...props }: TextFieldProps) => {
  const [field, meta] = useField(name);
  const id = `text-field-${name}`;
  return (
    <MuiTextField
      error={meta.touched && !!meta.error}
      variant="outlined"
      InputProps={{
        endAdornment,
        startAdornment,
      }}
      {...field}
      {...props}
      id={id}
    />
  );
};

export default TextField;
