import { PropsWithChildren } from "react";
import { IconButton, Stack, styled, useTheme } from "@mui/material";
import CloseIcon from "../../../icons/CloseIcon";
import Dialog from "../Dialog";
import StatusUpdate from "../../StatusUpdate";
import Text from "../../Text";

export type DestinationUpdateDialogProps = PropsWithChildren<{
  isOpen?: boolean;
  onClose?: () => void;
}>;

const Header = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 0, 2),
}));

const DestinationUpdateDialog = ({ isOpen, onClose, children }: DestinationUpdateDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Header direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Text variant="h5" fontStyle="SemiBold">
            Destination options
          </Text>
          <IconButton aria-label="close" onClick={onClose} sx={{ height: "32px", width: "32px", padding: 1 }}>
            <CloseIcon color={theme.palette.common.white} />
          </IconButton>
        </Header>
      }
    >
      <StatusUpdate>{children}</StatusUpdate>
    </Dialog>
  );
};

export default DestinationUpdateDialog;
