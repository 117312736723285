interface IconProps {
  color?: string;
}

const SearchIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_207_5047)">
        <path
          d="M37.536 35.2973L28.0851 25.8463C30.6606 22.6964 31.9268 18.6771 31.622 14.6198C31.3172 10.5625 29.4645 6.7775 26.4473 4.04782C23.43 1.31813 19.479 -0.14743 15.4115 -0.0457298C11.344 0.0559707 7.47118 1.71715 4.59412 4.59421C1.71706 7.47127 0.0558791 11.3441 -0.0458213 15.4116C-0.147522 19.4791 1.31804 23.4301 4.04772 26.4473C6.77741 29.4646 10.5624 31.3172 14.6197 31.6221C18.6771 31.9269 22.6964 30.6607 25.8462 28.0852L35.2972 37.5361C35.5958 37.8245 35.9957 37.9841 36.4109 37.9805C36.826 37.9769 37.2231 37.8104 37.5167 37.5168C37.8103 37.2232 37.9768 36.8261 37.9804 36.411C37.984 35.9958 37.8244 35.5959 37.536 35.2973ZM15.8332 28.5C13.328 28.5 10.879 27.7571 8.79601 26.3653C6.71299 24.9735 5.08947 22.9952 4.13076 20.6807C3.17205 18.3661 2.92121 15.8193 3.40995 13.3622C3.8987 10.9051 5.10508 8.64811 6.87655 6.87664C8.64802 5.10517 10.905 3.89879 13.3621 3.41004C15.8192 2.9213 18.366 3.17214 20.6806 4.13085C22.9951 5.08956 24.9734 6.71308 26.3652 8.7961C27.757 10.8791 28.4999 13.3281 28.4999 15.8333C28.4961 19.1916 27.1604 22.4112 24.7858 24.7859C22.4111 27.1605 19.1915 28.4962 15.8332 28.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_207_5047">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
