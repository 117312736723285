import * as yup from "yup";
import { mockCrewMembers } from "../../../__MOCKS__/crew";

export type SelectionWithComments<T> = { selection: T; comments?: string };

const booleanSelectionWithCommentsValidationSchema = yup.object().shape({
  selection: yup.boolean().nullable(),
  comments: yup.string(),
});

export enum VehicleLoginFormField {
  Vehicle = "vehicle",
  Crew = "crew",
  Inspection = "inspection",
  PreShiftChecklist = "abcChecklist",
}

/**
 * Vehicle
 */

export enum VehicleField {
  Region = "region",
  Station = "station",
  Code = "code",
  CallSign = "callSign",
  PortableDevices = "portableDevices",
}

export interface VehicleSchema {
  [VehicleField.Region]: string;
  [VehicleField.Station]: string;
  [VehicleField.Code]: string;
  [VehicleField.CallSign]: string;
  [VehicleField.PortableDevices]: string;
}

export const vehicleValidationSchema = yup.object().shape({
  [VehicleLoginFormField.Vehicle]: yup
    .object()
    .shape({
      [VehicleField.Region]: yup.string(),
      [VehicleField.Station]: yup.string(),
      [VehicleField.Code]: yup.string().required(),
      [VehicleField.CallSign]: yup.string(),
      [VehicleField.PortableDevices]: yup.string(),
    })
    .required(),
});

const vehicleInitialValues: VehicleSchema = {
  region: "",
  station: "",
  code: "1234",
  callSign: "",
  portableDevices: "",
};

/**
 * Crew
 */

export enum CrewField {
  Members = "members",
  Observers = "observers",
}

export interface CrewSchema {
  [CrewField.Members]: string[];
}

export const crewValidationSchema = yup.object().shape({
  [VehicleLoginFormField.Crew]: yup
    .object()
    .shape({
      [CrewField.Members]: yup.array().of(yup.string()).required().min(1),
    })
    .required(),
});

const crewInitialValues: CrewSchema = {
  members: [mockCrewMembers[0].id, mockCrewMembers[1].id],
};

/**
 * Inspection
 */

export enum InspectionField {
  Vehicle = "vehicle",
  EmergencyDevices = "emergencyDevices",
  StretcherChair = "stretcherChair",
  StrapsAndBoards = "strapsAndBoards",
}

export interface InspectionSchema {
  [InspectionField.Vehicle]: SelectionWithComments<boolean | null>;
  [InspectionField.EmergencyDevices]: SelectionWithComments<boolean | null>;
  [InspectionField.StretcherChair]: SelectionWithComments<boolean | null>;
  [InspectionField.StrapsAndBoards]: SelectionWithComments<boolean | null>;
}

export const inspectionValidationSchema = yup.object().shape({
  [VehicleLoginFormField.Inspection]: yup
    .object()
    .shape({
      [InspectionField.Vehicle]: booleanSelectionWithCommentsValidationSchema,
      [InspectionField.EmergencyDevices]: booleanSelectionWithCommentsValidationSchema,
      [InspectionField.StretcherChair]: booleanSelectionWithCommentsValidationSchema,
      [InspectionField.StrapsAndBoards]: booleanSelectionWithCommentsValidationSchema,
    })
    .required(),
});

const inspectionSchemaInitialValues: InspectionSchema = {
  vehicle: { selection: null, comments: "" },
  emergencyDevices: { selection: null, comments: "" },
  stretcherChair: { selection: null, comments: "" },
  strapsAndBoards: { selection: null, comments: "" },
};

/**
 * ABC Checklist
 */

export enum PreShiftChecklistField {
  WhoIsItFor = "whoIsItFor",
  CovidSafe = "covidSafe",
  DrugAndAlcoholFree = "drugAndAlcoholFree",
  Fatigued = "fatigued",
}

export interface PreShiftChecklistSchema {
  [PreShiftChecklistField.WhoIsItFor]: SelectionWithComments<"myself" | "everyone" | null>;
  [PreShiftChecklistField.CovidSafe]: SelectionWithComments<boolean | null>;
  [PreShiftChecklistField.DrugAndAlcoholFree]: SelectionWithComments<boolean | null>;
  [PreShiftChecklistField.Fatigued]: SelectionWithComments<boolean | null>;
}

export const preShiftChecklistValidationSchema = yup.object().shape({
  [VehicleLoginFormField.Inspection]: yup
    .object()
    .shape({
      [PreShiftChecklistField.WhoIsItFor]: yup.object().shape({
        selection: yup.string().oneOf(["myself", "everyone"]),
        comments: yup.string(),
      }),
      [PreShiftChecklistField.CovidSafe]: booleanSelectionWithCommentsValidationSchema,
      [PreShiftChecklistField.DrugAndAlcoholFree]: booleanSelectionWithCommentsValidationSchema,
      [PreShiftChecklistField.Fatigued]: booleanSelectionWithCommentsValidationSchema,
    })
    .required(),
});

const preShiftChecklistInitialValues: PreShiftChecklistSchema = {
  whoIsItFor: { selection: null, comments: "" },
  covidSafe: { selection: null, comments: "" },
  drugAndAlcoholFree: { selection: null, comments: "" },
  fatigued: { selection: null, comments: "" },
};

/**
 * Complete Form
 */

export interface VehicleLoginFormSchema {
  vehicle: VehicleSchema;
  crew: CrewSchema;
  inspection: InspectionSchema;
  preShiftChecklist: PreShiftChecklistSchema;
}

export const vehicleLoginInitialValues: VehicleLoginFormSchema = {
  vehicle: vehicleInitialValues,
  crew: crewInitialValues,
  inspection: inspectionSchemaInitialValues,
  preShiftChecklist: preShiftChecklistInitialValues,
};

export type GetFieldNameProps = XOR<
  XOR<
    {
      prefix: VehicleLoginFormField.Vehicle;
      field: VehicleField;
      nestedField?: string;
    },
    {
      prefix: VehicleLoginFormField.Crew;
      field: CrewField;
      nestedField?: string;
    }
  >,
  XOR<
    {
      prefix: VehicleLoginFormField.Inspection;
      field: InspectionField;
      nestedField?: string;
    },
    {
      prefix: VehicleLoginFormField.PreShiftChecklist;
      field: PreShiftChecklistField;
      nestedField?: string;
    }
  >
>;

export const getFieldName = ({ prefix, field, nestedField }: GetFieldNameProps) =>
  prefix + "." + field + (nestedField ? `.${nestedField}` : "");
