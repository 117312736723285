import { ReactElement, useState } from "react";
import { Stack, styled, Box, useTheme, Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
// icons
import HomeIcon from "../../../icons/HomeIcon";
import HistoryIcon from "../../../icons/HistoryIcon";
import AmbulanceIcon from "../../../icons/AmbulanceIcon";
import LogoutIcon from "../../../icons/LogoutIcon";
import LogoffDialog from "../../Dialogs/LogoffDialog";
import ChatIcon from "../../../icons/ChatIcon";
// components
import Text from "../../Text";
import { useFooterRef } from "../../../context/FooterRefContext";
import ChatMessenger from "../../Chat/ChatMessenger";
import ChatMessage from "../../Chat/ChatMessage";

import { incidentActions, useIncidentDispatch } from "../../../context/IncidentContext";
import { Status, userActions, useUserDispatch, useUserState } from "../../../context/UserContext";

const Root = styled(Stack)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2, 3, "11px"),
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
}));

const ChatFab = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.green.main,
  "&:hover": {
    backgroundColor: theme.palette.green.main,
  },
}));

interface AppFooterIconProps<C = (props: { color?: string }) => ReactElement> {
  Icon: C;
  label: string;
  selected?: boolean;
  onClick?: () => void;
}

const AppFooterIcon = ({ Icon, label, selected, onClick }: AppFooterIconProps) => {
  const theme = useTheme();
  const color = selected ? theme.palette.common.white : theme.palette.secondary.main;

  return (
    <Stack alignItems="center" sx={{ cursor: "pointer" }} onClick={onClick}>
      <Box height="20px" width="20px">
        <Icon color={color} />
      </Box>
      <Text fontStyle="Regular" variant="caption" color={color}>
        {label}
      </Text>
    </Stack>
  );
};

export type MenuItem = "/" | "/crew-and-vehicle" | "/history";

export interface AppFooterProps {
  selectedItem?: MenuItem;
  isReady?: boolean;
}

const AppFooter = ({ selectedItem, isReady }: AppFooterProps) => {
  const navigate = useNavigate();
  const footerRef = useFooterRef();

  const [logoffDialogIsOpen, setLogoffDialogIsOpen] = useState(false);
  const showLogoffDialog = () => setLogoffDialogIsOpen(true);
  const hideLogoffDialog = () => setLogoffDialogIsOpen(false);

  const [chatIsOpen, setChatIsOpen] = useState(false);
  const toggleChat = () => setChatIsOpen(!chatIsOpen);
  const hideChat = () => setChatIsOpen(false);

  const incidentDispatch = useIncidentDispatch();

  const { status } = useUserState();
  const userDispatch = useUserDispatch();

  const selected = logoffDialogIsOpen ? "logoff-dialog" : selectedItem;

  const handleHomeBtnClick = () => {
    if (status === Status.Available) {
      //set incident to complete
      userActions.setStatus(userDispatch, Status.Available);
      //clear the incident
      incidentActions.clearIncident(incidentDispatch);
      //set it as complete to mock no incidents assigned
      incidentActions.incidentComplete(incidentDispatch);
    }
    navigate("/");
  };

  return (
    <>
      <Root direction="row" justifyContent="space-between" alignItems="center">
        <AppFooterIcon Icon={HomeIcon} label="Home" onClick={handleHomeBtnClick} selected={selected === "/"} />
        {isReady && (
          <AppFooterIcon
            Icon={AmbulanceIcon}
            label="Crew & Vehicle"
            onClick={() => {
              navigate("/crew-and-vehicle");
              userActions.setStatus(userDispatch, Status.OffDuty);
            }}
            selected={selected === "/crew-and-vehicle"}
          />
        )}
        <AppFooterIcon
          Icon={HistoryIcon}
          label="History"
          selected={selected === "/history"}
          onClick={() => navigate("/history")}
        />
        <AppFooterIcon
          Icon={LogoutIcon}
          label="Log off"
          selected={selected === "logoff-dialog"}
          onClick={showLogoffDialog}
        />
      </Root>
      <LogoffDialog isOpen={logoffDialogIsOpen} onClose={hideLogoffDialog} />

      {chatIsOpen && (
        <Stack
          sx={{
            position: "fixed",
            bottom: (footerRef.current?.offsetHeight ?? 0) + 64,
            right: 16,
          }}
        >
          <ChatMessenger hideChat={hideChat}>
            <ChatMessage sender="me" time="10:34 pm" message="Following up on previous patient" />
            <ChatMessage sender="Dispatch" time="10:35 pm" message="Acknowledged!" />
            <ChatMessage sender="me" time="10:36 pm" message="Thanks" />
          </ChatMessenger>
        </Stack>
      )}

      <ChatFab
        aria-label="open-chat"
        sx={{
          position: "fixed",
          bottom: (footerRef.current?.offsetHeight ?? 0) + 8,
          right: 16,
        }}
        size="medium"
        onClick={toggleChat}
      >
        <Box width="24px" height="24px">
          <ChatIcon />
        </Box>
      </ChatFab>
    </>
  );
};

export default AppFooter;
