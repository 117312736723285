import Dialog from "../Dialog";
// pages
import LockScreen from "../../../pages/LockScreen";

export interface NotificationLockScreenDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}

const LockScreenDialog = ({ isOpen, onClose }: NotificationLockScreenDialogProps) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} fullscreen>
      <LockScreen onClose={onClose} />
    </Dialog>
  );
};

export default LockScreenDialog;
