interface IconProps {
  color?: string;
}

const TickIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_266_4564)">
        <path
          d="M29.7586 5.908L11.3332 24.332C11.2094 24.4564 11.0621 24.5551 10.9 24.6224C10.7379 24.6897 10.5641 24.7244 10.3886 24.7244C10.213 24.7244 10.0392 24.6897 9.87712 24.6224C9.71501 24.5551 9.56779 24.4564 9.44391 24.332L2.31858 17.2C2.1947 17.0756 2.04749 16.977 1.88538 16.9096C1.72327 16.8423 1.54945 16.8076 1.37392 16.8076C1.19838 16.8076 1.02457 16.8423 0.862458 16.9096C0.700348 16.977 0.55313 17.0756 0.429251 17.2C0.304882 17.3239 0.206198 17.4711 0.138863 17.6332C0.0715274 17.7953 0.0368652 17.9691 0.0368652 18.1447C0.0368652 18.3202 0.0715274 18.494 0.138863 18.6561C0.206198 18.8182 0.304882 18.9655 0.429251 19.0893L7.55725 26.216C8.30918 26.9665 9.32817 27.3881 10.3906 27.3881C11.453 27.3881 12.472 26.9665 13.2239 26.216L31.6479 7.796C31.7721 7.67215 31.8706 7.52501 31.9378 7.36302C32.005 7.20104 32.0396 7.02738 32.0396 6.852C32.0396 6.67662 32.005 6.50296 31.9378 6.34098C31.8706 6.17899 31.7721 6.03186 31.6479 5.908C31.524 5.78363 31.3768 5.68495 31.2147 5.61761C31.0526 5.55028 30.8788 5.51561 30.7032 5.51561C30.5277 5.51561 30.3539 5.55028 30.1918 5.61761C30.0297 5.68495 29.8824 5.78363 29.7586 5.908Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_266_4564">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TickIcon;
