interface IconProps {
  color?: string;
}

const CloseBoldIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 28 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4744 14L27.4873 2.98828C28.1709 2.30466 28.1709 1.1963 27.4873 0.512731C26.8036 -0.17089 25.6953 -0.17089 25.0117 0.512731L14 11.5256L2.98826 0.512731C2.30464 -0.17089 1.19628 -0.17089 0.512715 0.512731C-0.17085 1.19635 -0.170905 2.30471 0.512715 2.98828L11.5256 14L0.512715 25.0117C-0.170905 25.6954 -0.170905 26.8037 0.512715 27.4873C1.19634 28.1708 2.3047 28.1709 2.98826 27.4873L14 16.4744L25.0117 27.4873C25.6953 28.1709 26.8036 28.1709 27.4872 27.4873C28.1708 26.8037 28.1708 25.6953 27.4872 25.0117L16.4744 14Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseBoldIcon;
