import { PropsWithChildren, useState } from "react";
import { Divider, MenuItem, Stack, useTheme, Box } from "@mui/material";
import { useFormikContext } from "formik";
import Webcam from "react-webcam";
import { VehicleLoginFormField, VehicleField, getFieldName, VehicleLoginFormSchema } from "./schema";
// icons
import TickIcon from "../../../icons/TickIcon";
import ScanWithLineIcon from "../../../icons/ScanWithLineIcon";
import ScanIcon from "../../../icons/ScanIcon";
// components
import Button from "../../Buttons/Button";
import Select from "../../FormInputs/Select";
import Text from "../../Text";
import TextField from "../../FormInputs/TextField";
import StepTitle from "./StepTitle";

const FormText = ({ children }: PropsWithChildren<unknown>) => (
  <Text variant="body2" fontStyle="Regular" color={({ palette }) => palette.grey[100]}>
    {children}
  </Text>
);

export interface VehicleFormProps {
  goBack: () => void;
  scanBarcode: () => void;
}

const VehicleForm = ({ goBack, scanBarcode }: VehicleFormProps) => {
  const theme = useTheme();
  const formik = useFormikContext<VehicleLoginFormSchema>();

  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <StepTitle goBack={goBack}>Step 1: Assign Vehicle</StepTitle>

        <Stack spacing={2}>
          <Select
            name={getFieldName({
              prefix: VehicleLoginFormField.Vehicle,
              field: VehicleField.Region,
            })}
            placeholder="Region"
          >
            <MenuItem value="seq">South East Queensland</MenuItem>
            <MenuItem value="ipswich">Ipswich</MenuItem>
          </Select>

          <Select
            name={getFieldName({
              prefix: VehicleLoginFormField.Vehicle,
              field: VehicleField.Station,
            })}
            placeholder="Station"
          >
            <MenuItem value="sunnybank-hills">Sunnybank Hills</MenuItem>
            <MenuItem value="mt-gravatt">Mt Gravatt</MenuItem>
            <MenuItem value="woodridge">Woodridge</MenuItem>
          </Select>
        </Stack>
      </Stack>

      <Divider />

      <Stack spacing={2} paddingBottom={2}>
        <FormText>Please scan vehicle, call sign, devices code:</FormText>
        <Button
          color="purple"
          endIcon={
            <Box height="24px" width="28px">
              <ScanWithLineIcon color={theme.palette.purple.contrastText} />
            </Box>
          }
          onClick={scanBarcode}
        >
          Scan Barcode
        </Button>
      </Stack>

      <Stack spacing={2}>
        <FormText>Or if you cannot scan, please enter code manually:</FormText>
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.Vehicle,
            field: VehicleField.Code,
          })}
          placeholder="Code"
          fullWidth
          // this is to simulate a valid field input state for demo purposes
          // recommendation: implement it into the TextField component when form validation is important
          endAdornment={
            formik.getFieldMeta(
              getFieldName({
                prefix: VehicleLoginFormField.Vehicle,
                field: VehicleField.Code,
              }),
            ).error ? undefined : (
              <Box width="24px" height="24px">
                <TickIcon />
              </Box>
            )
          }
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.Vehicle,
            field: VehicleField.CallSign,
          })}
          placeholder="Call sign"
          fullWidth
        />
        <TextField
          name={getFieldName({
            prefix: VehicleLoginFormField.Vehicle,
            field: VehicleField.PortableDevices,
          })}
          placeholder="Portable devices"
          fullWidth
        />
      </Stack>
    </Stack>
  );
};

interface ScanBarcodeProps {
  returnToForm: () => void;
}

const ScanBarcode = ({ returnToForm }: ScanBarcodeProps) => {
  const theme = useTheme();

  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <StepTitle goBack={returnToForm}></StepTitle>

        <Stack spacing={5} alignItems="center">
          <Text variant="h4" fontStyle="SemiBold" textAlign="center">
            Scan code
          </Text>

          <Stack alignItems="center" justifyContent="center" position="relative">
            <Box height="50vw" width="50vw" position="absolute">
              <ScanIcon color={theme.palette.grey[600]} />
            </Box>
            <Webcam width="80%" />
          </Stack>

          <Stack width="197px">
            <Stack direction="row" justifyContent="space-between">
              <Text variant="h4" fontStyle="SemiBold">
                Vehicle
              </Text>
              <Box height="24px" width="24px" marginRight={1}>
                <TickIcon color={theme.palette.common.white} />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Text variant="h4" fontStyle="SemiBold">
                Call sign
              </Text>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Text variant="h4" fontStyle="SemiBold">
                Devices
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export interface VehicleProps {
  goBack: () => void;
}

const Vehicle = ({ goBack }: VehicleProps) => {
  const [view, setView] = useState<"form" | "scan-barcode">("form");

  return {
    form: <VehicleForm goBack={goBack} scanBarcode={() => setView("scan-barcode")} />,
    "scan-barcode": <ScanBarcode returnToForm={() => setView("form")} />,
  }[view];
};

export default Vehicle;
