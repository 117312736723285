import { PropsWithChildren, createContext, useContext, Dispatch, useReducer } from "react";
import produce from "immer";
import { sleep } from "../utils/time";

/**
 * State
 */

export interface AuthState {
  isLoading: boolean;
  error: Error | null;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  error: null,
  isAuthenticated: false,
};

/**
 * Reducer
 */

type AuthAction = { type: "LOGIN_STARTED" } | { type: "LOGIN_SUCCESS" } | { type: "LOGOFF" };

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  const type = action.type;
  switch (type) {
    case "LOGIN_STARTED":
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case "LOGIN_SUCCESS":
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.isAuthenticated = true;
      });
    case "LOGOFF":
      return produce(state, (draft) => {
        draft.isAuthenticated = false;
      });

    default:
      console.warn(`AuthAction ${type} not supported`);
      return state;
  }
};

const authActions = {
  login: async (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: "LOGIN_STARTED" });
    await sleep(2000);
    dispatch({ type: "LOGIN_SUCCESS" });
  },
  logoff: async (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: "LOGOFF" });
  },
};

/**
 * Context
 */

const AuthStateContext = createContext<AuthState>(undefined!);
const AuthDispatchContext = createContext<Dispatch<AuthAction>>(undefined!);

export const AuthProvider = ({ children, ...defaultState }: PropsWithChildren<Partial<AuthState>>) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...defaultState });
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = (): AuthState => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be within AuthProvider");
  }
  return context;
};

const useAuthDispatch = (): Dispatch<AuthAction> => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be within AuthProvider");
  }
  return context;
};

export { useAuthState, useAuthDispatch, authActions };
