interface IconProps {
  color?: string;
}

const LogoutIcon = ({ color = "#FFF" }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_82_2824)">
        <path
          d="M36.1458 14.5223L30.0041 8.38059C29.7055 8.09217 29.3055 7.93258 28.8904 7.93619C28.4752 7.93979 28.0781 8.10631 27.7845 8.39987C27.491 8.69344 27.3245 9.09056 27.3209 9.5057C27.3172 9.92085 27.4768 10.3208 27.7653 10.6194L33.907 16.7612C34.0895 16.9473 34.2489 17.1546 34.382 17.3787C34.3583 17.3787 34.3393 17.366 34.3155 17.366L9.4825 17.4167C9.06258 17.4167 8.65985 17.5835 8.36292 17.8804C8.06599 18.1774 7.89917 18.5801 7.89917 19C7.89917 19.4199 8.06599 19.8227 8.36292 20.1196C8.65985 20.4165 9.06258 20.5833 9.4825 20.5833L34.306 20.5327C34.3503 20.5327 34.3868 20.5105 34.4295 20.5073C34.2891 20.7752 34.111 21.0215 33.9007 21.2388L27.7589 27.3806C27.6077 27.5266 27.4871 27.7014 27.4041 27.8945C27.3211 28.0877 27.2774 28.2955 27.2756 28.5057C27.2738 28.7159 27.3138 28.9244 27.3935 29.119C27.4731 29.3136 27.5906 29.4904 27.7393 29.639C27.888 29.7877 28.0647 29.9053 28.2593 29.9849C28.4539 30.0645 28.6624 30.1046 28.8726 30.1027C29.0829 30.1009 29.2906 30.0572 29.4838 29.9743C29.677 29.8913 29.8517 29.7707 29.9978 29.6194L36.1395 23.4777C37.3268 22.29 37.9938 20.6794 37.9938 19C37.9938 17.3206 37.3268 15.71 36.1395 14.5223H36.1458Z"
          fill={color}
        />
        <path
          d="M11.0833 34.8333H7.91667C6.65689 34.8333 5.44871 34.3329 4.55791 33.4421C3.66711 32.5513 3.16667 31.3431 3.16667 30.0833V7.91667C3.16667 6.65689 3.66711 5.44871 4.55791 4.55791C5.44871 3.66711 6.65689 3.16667 7.91667 3.16667H11.0833C11.5033 3.16667 11.906 2.99985 12.2029 2.70292C12.4999 2.40599 12.6667 2.00326 12.6667 1.58333C12.6667 1.16341 12.4999 0.76068 12.2029 0.463748C11.906 0.166815 11.5033 0 11.0833 0L7.91667 0C5.81781 0.00251411 3.80563 0.837396 2.32151 2.32151C0.837396 3.80563 0.00251411 5.81781 0 7.91667L0 30.0833C0.00251411 32.1822 0.837396 34.1944 2.32151 35.6785C3.80563 37.1626 5.81781 37.9975 7.91667 38H11.0833C11.5033 38 11.906 37.8332 12.2029 37.5363C12.4999 37.2393 12.6667 36.8366 12.6667 36.4167C12.6667 35.9967 12.4999 35.594 12.2029 35.2971C11.906 35.0001 11.5033 34.8333 11.0833 34.8333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_82_2824">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
