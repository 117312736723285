import { Button as MuiButton, ButtonProps as MuiButtonProps, styled } from "@mui/material";

export type ButtonVariant = "primary" | "outlined";
export type ButtonColor = "blue" | "pink" | "purple" | "neutral";

export interface ButtonProps extends Omit<MuiButtonProps, "color" | "size" | "variant"> {
  color?: ButtonColor;
  medium?: boolean;
  outlined?: boolean;
  fullWidth?: boolean;
}

const ButtonBase = styled(MuiButton)(({ size }) => ({
  height: { small: "44px", medium: "58px", large: "58px" }[size ?? "small"],
  fontSize: { small: "16px", medium: "20px", large: "20px" }[size ?? "small"],
  padding: { small: "10px 20px", medium: "14px 30px", large: "14px 30px" }[size ?? "small"]
}));

// TODO - button sizes appear to be based on device width (mobile/tablet) for now just designing for mobile (small)
const Button = ({ color = "blue", outlined = false, medium = false, ...props }: ButtonProps) => {
  return (
    <ButtonBase
      color={color}
      size={medium ? "medium" : "small"}
      variant={outlined ? "outlined" : "contained"}
      {...props}
    />
  );
};

export default Button;
