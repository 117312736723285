import { Children, ReactNode, ReactElement } from "react";
import { SelectOption } from "@mui/base";

export function getSelectOptionsFromChildren<TValue>(children: ReactNode): SelectOption<TValue>[] {
  if (children == null) {
    return [];
  }

  const options = Children.map(children, (node) => {
    const element = node as ReactElement;
    const option: SelectOption<TValue> = {
      value: element.props.value,
      label: element.props.label || element.props.children,
      disabled: element.props.disabled ?? false,
    };
    return option;
  });

  return options ?? [];
}
