import { Box, Stack } from "@mui/material";

export interface CheckboxStepLabelProps {
  step: number;
  label: string;
}

const CheckboxStepLabel = ({ step, label }: CheckboxStepLabelProps) => (
  <Stack spacing={4} direction="row">
    <Box position="absolute">{step}.</Box>
    <Box>{label}</Box>
  </Stack>
);

export default CheckboxStepLabel;
