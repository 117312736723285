import { styled, Badge, BadgeProps } from "@mui/material";

export type StandaloneBadgeProps = BadgeProps;

const StandaloneBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    transform: "scale(1) translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    transformOrigin: "0 0",
  },
  "& .MuiBadge-badge.MuiBadge-invisible": {
    transform: "scale(0) translate(-50%, -50%)",
  },
}));

export default StandaloneBadge;
