import { Avatar as MuiAvatar, styled } from "@mui/material";
import { Theme } from "@mui/material";

const StyledAvatar = styled(MuiAvatar)(() => ({
  borderStyle: "solid",
}));

export interface AvatarProps {
  borderColor?: string | ((theme: Theme) => string);
  size?: string;
  filter?: string;
  onClick?: () => void;
}

const Avatar = ({ borderColor, size = "40px", filter, onClick, ...props }: AvatarProps) => {
  return (
    <StyledAvatar
      src="/stock-avatar.png"
      sx={{ height: size, width: size, borderColor, borderWidth: borderColor ? "1px" : 0 }}
      imgProps={{
        sx: {
          filter,
        },
      }}
      onClick={onClick}
      {...props}
    />
  );
};

export default Avatar;
