export const generalStatuses: string[] = [
  "Stand by",
  "Unserviceable",
  "Available",
  "On call",
  "Out in area",
  "Arrived at station",
  "Assigned to station",
];

export const preCannedMessages: string[] = ["Yes", "No", "ETA", "Offloaded but cleaning", "Completing paperwork"];
