import { ToggleButton, Box, useTheme } from "@mui/material";
import TakePhotoIcon from "../../../icons/TakePhotoIcon";

export interface CameraButtonProps {
  onChange?: () => void;
  onClick?: () => void;
}

const CameraButton = ({ onChange, onClick }: CameraButtonProps) => {
  const theme = useTheme();
  return (
    <ToggleButton value="Take photo of vehicle" color="primary" onChange={onChange} onClick={onClick}>
            <Box height="25px" width="25px">
              <TakePhotoIcon color={theme.palette.common.white} />
            </Box>
    </ToggleButton>
  );
};

export default CameraButton;
