import { createTheme, ThemeOptions } from "@mui/material";
import fontStyles from "./fontStyles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1A9FFF",
      contrastText: "#FFFFFF",
      "60": "#1A9FFF99",
      "40": "#1A9FFF66",
      "20": "#1A9FFF33",
      "10": "#E8F5FF",
    },
    blue: {
      main: "#1A9FFF",
      contrastText: "#FFFFFF",
      "60": "#1A9FFF99",
      "40": "#1A9FFF66",
      "20": "#1A9FFF33",
      "10": "#E8F5FF",
    },
    secondary: {
      main: "#23DBB9",
      dark: "#0AD3BB",
      "60": "#23DBB999",
      "40": "#23DBB966",
      "20": "#23DBB933",
    },
    green: {
      main: "#23DBB9",
      dark: "#0AD3BB",
      "60": "#7BE9D5",
      "40": "#A7F1E3",
      "20": "#D3F8F1",
    },
    pink: {
      main: "#FF165E",
      contrastText: "#FFFFFF",
      "60": "#FF165E99",
      "40": "#FF165E66",
      "20": "#FF165E33",
    },
    purple: {
      main: "#9C27B0",
      contrastText: "#FFFFFF",
      "60": "#9C27B099",
      "40": "#9C27B066",
      "20": "#9C27B033",
    },
    error: {
      main: "#FF165E",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#23DBB9",
    },
    warning: {
      main: "#F4F90B",
    },
    neutral: {
      main: "#C7CFE2",
    },
    divider: "#7282A3",
    grey: {
      "100": "#F6F8FF",
      "200": "#F8FAFE",
      "300": "#E9EEF9",
      "400": "#C7CFE2",
      "500": "#96A6C7",
      "600": "#7282A3",
      "700": "#273146",
      "800": "#273146",
      "900": "#181F2E",
    },
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    background: {
      default: "#3B4457",
      paper: "#181F2E",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: "6px",
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: ["Poppins", "Raleway", "Arial"].join(","),
    allVariants: {
      color: "#C7CFE2",
    },
    h1: {
      fontSize: "3rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
      letterSpacing: "0.2px",
    },
    h2: {
      fontSize: "2.125rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
      letterSpacing: "0.25px",
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
    },
    h5: {
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Medium,
      letterSpacing: "0.5px",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
      letterSpacing: "0.2px",
    },
    body2: {
      fontSize: "0.8125rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
      letterSpacing: "0.2px",
    },
    button: {
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.SemiBold,
      letterSpacing: "1.25px",
    },
    caption: {
      fontSize: "0.6875rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
      letterSpacing: "0.4px",
    },
    overline: {
      fontSize: "0.625rem",
      lineHeight: 1.5,
      fontWeight: fontStyles.Regular,
      letterSpacing: "1.5px",
    },
  },
});

const darkTheme = createTheme(theme, {
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
        startIcon: {
          marginRight: theme.spacing(2),
        },
        endIcon: {
          marginLeft: theme.spacing(2),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.5),
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          width: "15px",
          height: "15px",
          "& .MuiBadge-badge": {
            minWidth: "15px",
            height: "15px",
            padding: "0",
            ...theme.typography.caption,
            fontWeight: fontStyles.SemiBold,
          },
        },
        colorPrimary: {
          color: theme.palette.pink.contrastText,
          backgroundColor: theme.palette.pink.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          textTransform: "none",
          fontWeight: fontStyles.SemiBold,
          color: theme.palette.grey[500],
          padding: theme.spacing(0, 1.5, 1.5),
          minWidth: 0,
          minHeight: 0,
          "&.Mui-selected": {
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          overflowY: "visible",
          minHeight: 0,
        },
        scroller: {
          padding: theme.spacing(0, 2),
        },
        indicator: {
          backgroundColor: theme.palette.common.white,
        },
        flexContainer: {
          boxShadow: `inset 0 -2px 0 0 ${theme.palette.grey[500]}`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          "&:before": {
            opacity: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "56px",
          },
        },
        content: {
          margin: theme.spacing(2, 0),
          "&.Mui-expanded": {
            margin: theme.spacing(2, 0),
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 2, 2),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          borderRadius: "4px",
          color: theme.palette.grey[200],
          borderColor: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[800],
          "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
            borderColor: theme.palette.common.white,
          },
        },
        input: {
          "&::placeholder": {
            color: theme.palette.grey[600],
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(0.5),
          ".MuiPaper-root": {
            borderRadius: "4px",
          },
          ".MuiMenuItem-root.Mui-selected": {
            fontWeight: fontStyles.SemiBold,
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
          },
        },
        list: {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          color: theme.palette.grey[200],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          marginBottom: theme.spacing(1),
          color: theme.palette.grey[100],
          "&.Mui-focused": {
            color: theme.palette.grey[100],
          },
          "&.Mui-disabled": {
            color: theme.palette.grey[400],
          },
          "&.standout": {
            ...theme.typography.body1,
            fontWeight: fontStyles.SemiBold,
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
          color: theme.palette.grey[100],
          "&.Mui-disabled": {
            color: theme.palette.grey[400],
          },
          "&.standout": {
            ...theme.typography.body1,
            fontWeight: fontStyles.SemiBold,
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          margin: theme.spacing(0.5, 2, 0.5, 0),
          color: theme.palette.grey[100],
          height: theme.spacing(4),
          width: theme.spacing(4),
          ".MuiSvgIcon-root": {
            height: theme.spacing(2),
            width: theme.spacing(2),
          },
          "&.Mui-Checked": {
            color: theme.palette.grey[100],
          },
          "&.Mui-disabled": {
            color: theme.palette.grey[400],
          },
        },
      },
    },
  },
} as ThemeOptions);

export default darkTheme;
