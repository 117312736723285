import { createContext, Dispatch, PropsWithChildren, useContext, useReducer } from "react";
import produce from "immer";
import { Incident } from "../types";

/**
 * State
 */

export interface IncidentState {
  acknowledged: boolean;
  incident: Incident | null;
  complete: boolean;
}

const initialState: IncidentState = {
  acknowledged: false,
  incident: null,
  complete: false,
};

/**
 * Reducer
 */

type IncidentAction =
  | { type: "SET_INCIDENT"; data: Incident }
  | { type: "CLEAR_INCIDENT" }
  | { type: "ACKNOWLEDGE_INCIDENT" }
  | { type: "COMPLETE_INCIDENT" }
  | { type: "FORCE_INCOMPLETE_INCIDENT" };

const reducer = (state: IncidentState, action: IncidentAction) => {
  const type = action.type;
  switch (type) {
    case "SET_INCIDENT":
      return produce(state, (draft) => {
        draft.incident = action.data;
      });
    case "CLEAR_INCIDENT":
      return produce(state, (draft) => {
        draft.acknowledged = false;
        draft.incident = null;
        draft.complete = false;
      });
    case "ACKNOWLEDGE_INCIDENT":
      return produce(state, (draft) => {
        draft.acknowledged = true;
        draft.complete = false;
      });

    case "COMPLETE_INCIDENT":
      return produce(state, (draft) => {
        draft.complete = true;
        draft.incident = null;
      });

    default:
      console.warn(`IncidentAction ${type} not supported`);
      return state;
  }
};

const incidentActions = {
  setIncident: (dispatch: Dispatch<IncidentAction>, data: Incident) => {
    dispatch({ type: "SET_INCIDENT", data });
  },
  clearIncident: (dispatch: Dispatch<IncidentAction>) => {
    dispatch({ type: "CLEAR_INCIDENT" });
  },
  acknowledgeIncident: (dispatch: Dispatch<IncidentAction>) => {
    dispatch({ type: "ACKNOWLEDGE_INCIDENT" });
  },
  incidentComplete: (dispatch: Dispatch<IncidentAction>) => {
    dispatch({ type: "COMPLETE_INCIDENT" });
  },
};

/**
 * Context
 */

const IncidentStateContext = createContext<IncidentState>(undefined!);
const IncidentDispatchContext = createContext<Dispatch<IncidentAction>>(undefined!);

export const IncidentProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <IncidentStateContext.Provider value={state}>
      <IncidentDispatchContext.Provider value={dispatch}>{children}</IncidentDispatchContext.Provider>
    </IncidentStateContext.Provider>
  );
};

const useIncidentState = (): IncidentState => {
  const context = useContext(IncidentStateContext);
  if (context === undefined) {
    throw new Error("useIncidentState must be within IncidentProvider");
  }
  return context;
};

const useIncidentDispatch = (): Dispatch<IncidentAction> => {
  const context = useContext(IncidentDispatchContext);
  if (context === undefined) {
    throw new Error("useIncidentDispatch must be within IncidentProvider");
  }
  return context;
};

export { useIncidentState, useIncidentDispatch, incidentActions };
