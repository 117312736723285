import React, { useEffect, useState } from "react";
import { Container, styled, CircularProgress, Stack, Tabs, Tab, Portal } from "@mui/material";
import Text from "../../../components/Text";
import { useHeaderRef } from "../../../context/HeaderRefContext";
import StandaloneBadge from "../../../components/StandaloneBadge";
import { useNavigate } from "react-router-dom";

const Map = styled(Container)(() => ({
  height: "100%",
  width: "100%",
  position: "relative",
  overflow: "hidden",
}));

const CalculatingRouteContainer = styled(Container)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const CalculatingRouteIndicator = styled(CircularProgress)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(2),
}));

const RouteToIncident: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const headerRef = useHeaderRef();
  const navigate = useNavigate();

  const appHeaderEl = headerRef.current;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Stack spacing={2}>
      <Portal container={appHeaderEl}>
        <Tabs value={2} variant="scrollable" allowScrollButtonsMobile>
          <Tab
            id="notificaiton"
            label="Notification"
            onClick={() => {
              navigate("/");
            }}
            icon={<StandaloneBadge badgeContent={2} color="primary" />}
            iconPosition="end"
          />
          <Tab
            id="statusUpdate"
            label="Status update"
            onClick={() => {
              navigate("/");
            }}
          />
          <Tab id="map" key="map" label="Map" onClick={() => navigate("/incident/map")} />
          <Tab id="details" key="details" label="Details" onClick={() => navigate("/incident")} />
        </Tabs>
      </Portal>
      {loading ? (
        <CalculatingRouteContainer fixed maxWidth="xs" disableGutters={true}>
          <CalculatingRouteIndicator />
          <Text variant="h5">Calculating route to incident</Text>
        </CalculatingRouteContainer>
      ) : (
        <Map fixed maxWidth="xs" disableGutters={true}>
          <img src="/map_static_incident_sm.png" alt="Route To Incident Map View" style={{ width: "100%" }} />
        </Map>
      )}
    </Stack>
  );
};

export default RouteToIncident;
