import { PropsWithChildren } from "react";
import { Stack } from "@mui/material";
import BackButton from "../../Buttons/BackButton";
import Text from "../../Text";

export type StepTitleProps = PropsWithChildren<{
  goBack: () => void;
}>;

const StepTitle = ({ children, goBack }: StepTitleProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2} marginTop={-1}>
      <BackButton goBack={goBack} />
      <Text variant="h5" fontStyle="SemiBold">
        {children}
      </Text>
    </Stack>
  );
};

export default StepTitle;
