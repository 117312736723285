import { useState } from "react";
import { IconButton, Stack, styled, Box, useTheme } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import EyeIcon from "../../icons/EyeIcon";
// context
import { authActions, useAuthDispatch } from "../../context/AuthContext";
// components
import TextField from "../../components/FormInputs/TextField";
import Text from "../../components/Text";
import { Link } from "react-router-dom";
import LoadingButton from "../../components/Buttons/LoadingButton";

const Root = styled(Stack)(({ theme }) => ({
  height: "100%",
  position: "relative",
  justifyContent: "space-between",
  padding: theme.spacing(2),
}));

const Logo = styled(Box)(() => ({
  backgroundImage: 'url("/logo.png")',
  backgroundSize: "contain",
  position: "relative",
  height: "65px",
  width: "207px",
  left: "50%",
  top: "19vh",
  transform: "translate(-50%, 0)",
}));

const Login = () => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authDispatch = useAuthDispatch();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const togglePasswordVisibility = () => setPasswordVisibility(!passwordVisibility);

  const formik = useFormik({
    initialValues: {
      email: "Sample@ambulance.com.au",
      password: "12chPassword",
    },
    onSubmit: async () => {
      try {
        setIsSubmitting(true);
        await authActions.login(authDispatch);
      } catch (e) {
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <Root>
      <Logo />
      <FormikProvider value={formik}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={5}>
            <Stack spacing={3}>
              <TextField name="email" placeholder="Email" fullWidth />
              <TextField
                name="password"
                placeholder="Password"
                fullWidth
                type={passwordVisibility ? undefined : "password"}
                endAdornment={
                  <IconButton aria-label="" onClick={togglePasswordVisibility}>
                    <Box height="24px" width="24px">
                      <EyeIcon color={theme.palette.grey[600]} />
                    </Box>
                  </IconButton>
                }
              />
              <LoadingButton isLoading={isSubmitting} color="blue" fullWidth disabled={!formik.isValid} type="submit">
                Log in
              </LoadingButton>
            </Stack>

            <Stack alignItems="center">
              <Text variant="caption" fontStyle="Regular">
                Forgot your password?
              </Text>
              <Link to="/" style={{ textDecorationColor: theme.palette.common.white }}>
                <Text variant="caption" fontStyle="Regular">
                  Click here
                </Text>
              </Link>
            </Stack>
          </Stack>
        </form>
      </FormikProvider>
    </Root>
  );
};

export default Login;
