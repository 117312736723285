import { Stack } from "@mui/material";
import Text from "../../Text";

export interface ChatMessageProps {
  sender: string;
  time: string;
  message: string;
}

const ChatMessage = ({ sender, time, message }: ChatMessageProps) => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Text variant="body2" fontStyle="SemiBold">
          {sender}
        </Text>
        <Text variant="caption" textTransform="uppercase">
          {time}
        </Text>
      </Stack>
      <Stack>
        <Text variant="body2">{message}</Text>
      </Stack>
    </Stack>
  );
};

export default ChatMessage;
