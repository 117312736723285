import { Button as MuiButton } from "@mui/material";
import Text from "../../Text";

export interface ChangeButtonProps {
  onChange?: () => void;
}

const ChangeButton = ({ onChange }: ChangeButtonProps) => {
  return (
    <MuiButton
      color="neutral"
      onChange={onChange}
      sx={{
        textTransform: "none",
      }}
    >
      <Text variant="body2" fontStyle="Regular">
        Change
      </Text>
    </MuiButton>
  );
};

export default ChangeButton;
