import { Box, IconButton, useTheme } from "@mui/material";
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";

export interface BackButtonProps {
  goBack?: () => void;
  height?: string;
  width?: string;
}

const BackButton = ({ goBack, height = "21px", width = "21px" }: BackButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton aria-label="back" onClick={goBack} sx={{ marginLeft: -0.5, height: "32px", width: "32px" }}>
      <Box display="flex" height={height} width={width} justifyContent="center" alignItems="center">
        <ArrowLeftIcon color={theme.palette.grey[400]} />
      </Box>
    </IconButton>
  );
};

export default BackButton;
