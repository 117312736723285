import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, useTheme } from "@mui/material";
import { mockIncidents } from "../../__MOCKS__/incidents";
// icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";
// context
import { incidentActions, useIncidentDispatch, useIncidentState } from "../../context/IncidentContext";
import { Status, userActions, useUserDispatch, useUserState } from "../../context/UserContext";
import { ReadyState, useVehicleState } from "../../context/VehicleContext";
// components
import GeneralAccordion from "../../components/Accordions/GeneralAccordion";
import IncidentBox from "../../components/IncidentBox";
import NotificationCard from "../../components/NotificationCard";
import Button from "../../components/Buttons/Button";
import Text from "../../components/Text";

const ReadyDashboard = () => {
  const navigate = useNavigate();

  const { incident, complete } = useIncidentState();
  const incidentDispatch = useIncidentDispatch();

  const viewAndRespondToIncident = async () => {
    incidentActions.setIncident(incidentDispatch, mockIncidents[0]);
    userActions.setStatus(userDispatch, Status.Acknowledged);
  };

  const { status } = useUserState();
  const userDispatch = useUserDispatch();
  const startMealBreak = () => userActions.setStatus(userDispatch, Status.OnMealBreak);
  const readyBoardTime = new Date();
  readyBoardTime.setHours(9, 20);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [status]);

  useEffect(() => {
    if (incident) {
      navigate("/incident");
    }
  }, [incident, navigate]);

  useEffect(() => {
    if (status === Status.Activated) {
      incidentActions.clearIncident(incidentDispatch);
    }
  }, [navigate, status, incidentDispatch]);

  return (
    <Stack spacing={3}>
      {!complete && (
        <IncidentBox
          title="PRIO 1 - Incident ID: 20012 S"
          message="Incident 20012s is assigned to you, please respond as soon as possible."
          viewAndRespond={viewAndRespondToIncident}
        >
          <strong>ProQA:</strong> 10C02 | <strong>Called:</strong> 9:27
          <br />
          <strong>Address:</strong> District Court, 415 George Street, Brisbane, QLD 4000
          <br />
          <strong>Type:</strong> Chest pain, Cardiac history
          <br />
          <strong>Patient:</strong> John Smith M 43year
        </IncidentBox>
      )}
      <GeneralAccordion
        stickyNotification={
          complete ? (
            <>
              <NotificationCard
                alertLevel="system"
                title="System message"
                dateTime={readyBoardTime}
                message="Mater Hopsital no CT Scanner until Midday 26 January 2023"
                icon="speaker"
              />
              <br></br>
              <NotificationCard
                alertLevel="system"
                title="System message"
                dateTime={readyBoardTime}
                message="Fentanyl dosage changes will occur today refer to your Field Reference Guide"
                icon="speaker"
              />
            </>
          ) : (
            <NotificationCard
              alertLevel="system"
              title="System message"
              dateTime={readyBoardTime}
              message="Area alert and general update is here!"
              icon="speaker"
            />
          )
        }
        startMealBreak={startMealBreak}
        showMealBreakButton={status === Status.Available}
      />
      {complete && (
        <Box display="flex" justifyContent="center">
          <Text>There are no current incidents allocated to you.</Text>
        </Box>
      )}
    </Stack>
  );
};

const NotReadyDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();
  const notReadyBoardTime = new Date();
  notReadyBoardTime.setHours(9, 17);

  useEffect(() => {
    userActions.setStatus(userDispatch, Status.OffDuty);
  }, [userDispatch]);

  return (
    <Stack spacing={3}>
      <GeneralAccordion
        stickyNotification={
          <NotificationCard
            alertLevel="system"
            title="System message"
            dateTime={notReadyBoardTime}
            message={
              <>
                Good Morning, Mike.
                <br />
                To recieve incidents, please log on to MDT.
              </>
            }
            icon="speaker"
          />
        }
      />
      <Button
        color="blue"
        fullWidth
        endIcon={
          <Box height="24px" width="24px">
            <ArrowRightIcon color={theme.palette.blue.contrastText} />
          </Box>
        }
        onClick={() => navigate("/vehicle-login")}
      >
        Log on MDT
      </Button>
    </Stack>
  );
};

const Dashboard = () => {
  const { readyState } = useVehicleState();

  return readyState === ReadyState.LOGGED_IN ? <ReadyDashboard /> : <NotReadyDashboard />;
};

export default Dashboard;
