import { CircularProgress, styled } from "@mui/material";
import Button, { ButtonProps } from "../Button";

export interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean;
}

const Spinner = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.neutral.main,
}));

const LoadingButton = ({ isLoading, disabled, children, ...props }: LoadingButtonProps) => {
  return (
    <Button disabled={disabled || isLoading} {...props}>
      {children}
      {isLoading && <Spinner size="1rem" />}
    </Button>
  );
};

export default LoadingButton;
